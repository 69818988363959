import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ContentCarousel } from '@chegg-ui/carousel';
import { IEnrollment, ICourse } from '../../interfaces';
import { CourseCard } from '../index';
import { CARD_TYPES, STATIC_SKILLS_CARD_STRINGS } from '../../constants';

interface IProps {
  cards: IEnrollment[] | ICourse[]
  name: string
}

const Carousel = ({ cards, name }: IProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { ENROLLMENT } = CARD_TYPES;

  return (
    <div className='max-w-full w-fit'>
      {name.replace('_', ' ') !== STATIC_SKILLS_CARD_STRINGS.IN_PROGRESS
        ? <div className='mb-[1.5rem] mt-[2.5rem] text-grey text-[1.875rem] font-aspira700'>{name.replace('_', ' ')}</div>
        : <div className='mt-[2.75rem]' />}

      {cards.length === 1
        ? (
          <CourseCard
            key={cards[0].id}
            course={cards[0].cardType === ENROLLMENT ? (cards[0] as IEnrollment).course : cards[0] as ICourse}
            enrollment={cards[0].cardType === ENROLLMENT ? cards[0] as IEnrollment : undefined}
          />
        ) : (
          <ContentCarousel
            currentSlide={currentSlide}
            onChangeSlide={setCurrentSlide}
            aria-label={name}
          >
            {cards && cards.map((card: IEnrollment | ICourse) => (
              <CourseCard
                key={card.id}
                course={card.cardType === ENROLLMENT ? (card as IEnrollment).course : card as ICourse}
                enrollment={card.cardType === ENROLLMENT ? card as IEnrollment : undefined}
              />
            ))}
          </ContentCarousel>
        )}
    </div>
  );
};

export default Carousel;
