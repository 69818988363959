import React from 'react';
import { createPortal } from 'react-dom';

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
}

const Portal = ({ isOpen, children }: IProps) => {
  if (!isOpen) return null;

  return createPortal(
    children,
  document.getElementById('root') as HTMLElement,
  );
};
export default Portal;
