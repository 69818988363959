import React, { useState } from 'react';
import { DROP_BUTTONS, FILTERS } from '../../constants';
import mobileArrow from '../../assets/images/mobile-arrow.svg';
import whiteCheck from '../../assets/images/white-check-icon.svg';
import closeDrawer from '../../assets/images/close-mobile-menu.svg';

interface IProps {
    defaultOption: { status: string, track: string };
    options: { status: string[], track: string[] };
    selectedOptionsStatus: string[];
    selectedOptionsTrack: string[];
    handleSelectedStatus: (tempSelected: string[]) => void;
    handleSelectedTrack: (tempSelected: string[]) => void;
}

const MobileDrawer = ({
  defaultOption, selectedOptionsStatus, selectedOptionsTrack, options, handleSelectedStatus, handleSelectedTrack,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedStatus, setTempSelectedStatus] = useState([...selectedOptionsStatus]);
  const [tempSelectedTrack, setTempSelectedTrack] = useState([...selectedOptionsTrack]);

  const { CLEAR, DONE } = DROP_BUTTONS;

  const handleCloseDrawer = (action: string) => {
    switch (action) {
      case DONE:
        handleSelectedStatus(tempSelectedStatus);
        handleSelectedTrack(tempSelectedTrack);
        setTempSelectedStatus([]);
        setTempSelectedTrack([]);
        setIsOpen(false);
        break;
      case CLEAR:
        handleSelectedStatus([]);
        handleSelectedTrack([]);
        setTempSelectedStatus([]);
        setTempSelectedTrack([]);
        setIsOpen(false);
        break;
      default:
        setTempSelectedStatus([]);
        setTempSelectedTrack([]);
        setIsOpen(false);
    }
  };

  const handleOpeningDrop = () => {
    if (!isOpen) {
      setTempSelectedStatus(selectedOptionsStatus);
      setTempSelectedTrack(selectedOptionsTrack);
    }
    setIsOpen(!isOpen);
  };

  const handleSelectedStatusOption = (option: string) => {
    if (tempSelectedStatus.includes(option)) {
      setTempSelectedStatus(tempSelectedStatus.filter(selected => selected !== option));
    } else {
      setTempSelectedStatus((prevState) => [...prevState, option]);
    }
  };

  const handleSelectedTrackOption = (option: string) => {
    if (tempSelectedTrack.includes(option)) {
      setTempSelectedTrack(tempSelectedTrack.filter(selected => selected !== option));
    } else {
      setTempSelectedTrack((prevState) => [...prevState, option]);
    }
  };

  return (
    <div className='w-full flex justify-center overscroll-contain'>
      {isOpen && (<div className='z-[100] fixed right-0 left-0 top-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md' aria-hidden onClick={() => setIsOpen(!isOpen)} />)}

      <div
        className='w-[95%] h-[3rem] rounded-[6.25rem] bg-white border border-1 border-[#302C41] flex justify-center items-center'
        onClick={() => handleOpeningDrop()}
        aria-hidden
      >
        <div className='mr-[0.5rem] text-[1.25rem] text-[#302C41] font-aspira700'>{FILTERS}</div>
        {[...selectedOptionsStatus, ...selectedOptionsTrack].length ? <div className='mr-[0.5rem]'>{`(${[...selectedOptionsStatus, ...selectedOptionsTrack].length})`}</div> : null}
        <img src={mobileArrow} alt='arrow' />
      </div>

      <div className={`w-screen rounded-t-[1.5rem] fixed bottom-0 z-[110] bg-white flex flex-col items-center transform transition-[height] duration-[500ms] ease-linear overflow-auto picker-scroll ${isOpen ? 'h-[80vh]' : 'h-[0vh]'}`}>
        <div className='w-full flex justify-center h-[3.688rem] items-center text-[1.25rem] text-[#302C41] font-aspira800 border-b border-b-1 border-b-[#CAC8D1] relative'>
          {FILTERS}
          <div className='absolute right-[1rem]' aria-hidden onClick={() => handleCloseDrawer(DONE)}>
            <img src={closeDrawer} alt='close' className='h-[1.25rem] w-[1.25rem]' />
          </div>
        </div>

        <div className='w-full overflow-auto picker-scroll'>
          <div className='w-full border-b border-b-1 border-b-[#CAC8D1] mt-[1rem]'>
            <div className='ml-[1rem] text-[1.125rem] text-[#302C41] font-aspira700'>{defaultOption.status}</div>
            {options.status.sort().map(option => (
              <div key={option} className='w-full p-[1rem] flex flex-start cursor-pointer' onClick={() => handleSelectedStatusOption(option)} aria-hidden>
                <div className={`mr-[0.75rem] w-[1.5rem] h-[1.5rem] rounded-[0.25rem] ${tempSelectedStatus.includes(option) ? 'bg-[#EB7100] flex justify-center items-center' : 'border border-1 border-[#534F63] bg-white'}`}>
                  <img src={whiteCheck} alt='small-white-arrow' className={`w-[1rem] h-[1rem] ${!tempSelectedStatus.includes(option) ? 'hidden' : ''}`} />
                </div>
                <div className='text-[#302C41] font-aspira400'>{option}</div>
              </div>
            ))}
          </div>

          <div className='w-full mt-[1rem] pb-[4.25rem]'>
            <div className='ml-[1rem] text-[1.125rem] text-[#302C41] font-aspira700'>{defaultOption.track}</div>
            {options.track.sort().map(option => (
              <div key={option} className='w-full p-[1rem] flex flex-start cursor-pointer' onClick={() => handleSelectedTrackOption(option)} aria-hidden>
                <div className={`mr-[0.75rem] w-[1.5rem] h-[1.5rem] rounded-[0.25rem] ${tempSelectedTrack.includes(option) ? 'bg-[#EB7100] flex justify-center items-center' : 'border border-1 border-[#534F63] bg-white'}`}>
                  <img src={whiteCheck} alt='small-white-arrow' className={`w-[1rem] h-[1rem] ${!tempSelectedTrack.includes(option) ? 'hidden' : ''}`} />
                </div>
                <div className='text-[#302C41] font-aspira400'>{option}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='w-full flex items-center fixed bottom-0 justify-between p-[1rem] border-t border-t-1 border-t-[#CAC8D1] h-[4rem] bg-white'>
          <div className='cursor-pointer underline underline-offset-2 text-[#928E9F] font-aspira550' onClick={() => handleCloseDrawer(CLEAR)} aria-hidden>
            {CLEAR}
          </div>
          <div className='cursor-pointer bg-[#302C41] h-[2.5rem] w-[6.063rem] text-[1.25rem] font-aspira700 text-white rounded-[6.25rem] flex justify-center items-center' onClick={() => handleCloseDrawer(DONE)} aria-hidden>
            {DONE}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDrawer;
