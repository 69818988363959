import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { SecondaryButton } from '@chegg-ui/button';
import { ICourse, IEnrollment } from '../../interfaces';
import courseCardImage from '../../assets/images/card-bg.jpg';
import {
  CardProgramProgress, Loader, PlayerContent, Portal,
} from '../index';
import { CLOCK_ICON, METHOD_OPTIONS, STATIC_SKILLS_CARD_STRINGS } from '../../constants';
import { courseEnrollmentsStore } from '../../store/CourseEnrollments';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import { BACKEND_ROUTES } from '../../services/backendRoutes';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';
import { useClickstreamNotification } from '../../rio/rioHooks/useClickstreamNotifications';
import { courseStructureStore } from '../../store/CourseStructure';
import { PLAYER } from '../../pages/route_links';
import { downloadCertificate } from '../../services/downloadCertifiacte';
import { calculateTime } from '../../utils';

interface IProps {
    course: ICourse;
    enrollment?: IEnrollment;
}

const CourseCard = ({ course, enrollment }: IProps) => {
  const navigate = useNavigate();
  const {
    QUIZ_SKILLS, CONTINUE_LEARNING, GET_CERTIFICATE, PRACTICE, START_LEARNING,
  } = STATIC_SKILLS_CARD_STRINGS;
  const { CREATE_COURSE_ENROLLMENT, GET_COURSE_VERSION_STRUCTURE } = BACKEND_ROUTES;
  const { POST, GET } = METHOD_OPTIONS;

  const [loadingCert, setLoadingCert] = useState(false);
  const [enrollments, setCourseEnrollments] = courseEnrollmentsStore((state) => [state.enrollments, state.setCourseEnrollments]);
  const [isStructureExist, setIsStructureExist] = useState(false);
  const [setCourseStructure] = courseStructureStore((state) => [state.setCourseStructure]);

  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const createCourseEnrollment = (course_version_id: number) => axiosFetch({
    method: POST,
    url: CREATE_COURSE_ENROLLMENT,
    data: { course_version_id },
  });

  const getCourseVersionStructure = (courseVersionId: number) => axiosFetch({
    method: GET,
    url: GET_COURSE_VERSION_STRUCTURE,
    data: { courseVersionId },
  });

  const getBlackButtonText = () => {
    if (!enrollment) return START_LEARNING;
    return enrollment.completed ? GET_CERTIFICATE : CONTINUE_LEARNING;
  };

  // TODO - we need to see if course is already in progress and decide if we even show it or not

  useEffect(() => {
    async function responseHandler() {
      const { url, data } = response;
      switch (url) {
        case CREATE_COURSE_ENROLLMENT:
          setCourseEnrollments([...enrollments, data]);
          await useClickstreamInteraction('click', 'button', 'get started', 'Get Started');
          await useClickstreamNotification('info', 'program started', `${course!.id}`);
          getCourseVersionStructure(data.course_version_id);
          break;
        case GET_COURSE_VERSION_STRUCTURE:
          setCourseStructure(data);
          setIsStructureExist(true);
          break;
        default:
          break;
      }
    }
    responseHandler();
  }, [response]);

  useEffect(() => {
    if (isStructureExist && enrollments && enrollments.length > 0) {
      const saveEnrollment = enrollments.find(existingEnrollment => existingEnrollment.course.id === course.id);
      if (enrollment && enrollment.completed) {
        navigate(generatePath(PLAYER, {
          courseId: course.id.toString(),
          versionId: enrollment.course_version_id.toString(),
          moduleId: 'module-1',
          screenNum: '0',
        }));
      } else if (saveEnrollment) {
        navigate(generatePath(PLAYER, {
          courseId: course.id.toString(),
          versionId: saveEnrollment.course_version_id.toString(),
          moduleId: saveEnrollment.current_module,
          screenNum: saveEnrollment.current_screen,
        }));
      } else if (enrollment) {
        navigate(generatePath(PLAYER, {
          courseId: course.id.toString(),
          versionId: enrollment.course_version_id.toString(),
          moduleId: enrollment.current_module,
          screenNum: enrollment.current_screen,
        }));
      }
    }
  }, [isStructureExist, enrollments]);

  const getCertificate = async () => {
    useClickstreamInteraction('click', 'button', 'download certificate', 'Download your certificate');
    setLoadingCert(true);
    await downloadCertificate(course);
    setLoadingCert(false);
  };

  return (
    <div className='relative'>
      <Portal isOpen={loading}>
        <div className='z-20 fixed right-0 left-0 top-0 bottom-0 flex justify-center items-center bg-white'><Loader /></div>
      </Portal>
      {course && !error && !loading && (
      <div
        className='relative w-[34.375rem] max-sm:w-[22.438rem] h-[24.688rem] max-sm:h-[32rem] shadow-lastStudiedBox bg-white rounded-[0.75rem] mr-[1rem] mb-[1rem]'
      >
        {course.image ? (<img src={course.image} alt='course' className='rounded-t-[0.75rem] w-[100%] h-[8.75rem] object-cover' loading='lazy' />)
          : (<img src={courseCardImage} alt='course' className='rounded-t-[0.75rem] w-[100%] h-[8.75rem]' loading='lazy' />)}
        <div className='px-[1.5rem]'>
          <div className='text-grey text-[1.375rem] font-aspira700 max-sm:text-[1rem] mt-[1.5rem] mb-[0.5rem]'>{course.name}</div>
          <div className='text-lightText w-full text-[1rem] max-sm:text-[0.875rem]'>
            <PlayerContent content={JSON.parse(course.description)} />
          </div>
          <div className='mt-5'>
            <div>
              {enrollment?.progress
                ? (
                  <div className={`flex mt-4 max-sm:w-[19.438rem] ${enrollment?.completed ? 'max-sm:bottom-[8.75rem]' : 'max-sm:bottom-20'} absolute bottom-6 left-6`}>
                    <CardProgramProgress numerator={enrollment.progress.filter((module: any, index: number) => module[`module-${index + 1}`].completed).length} denominator={enrollment.progress.length} />
                  </div>
                )
                : (
                  <div className='flex flex-wrap max-sm:flex-col max-sm:gap-[1.563rem] items-center absolute max-sm:bottom-[5.5rem] left-[1.5rem]'>
                    {QUIZ_SKILLS.map(({ TEXT, ICON }, index) => (
                      <div key={TEXT} className={`flex w-[15.188rem] ${index === 1 && 'sm:justify-end'} ${index + 1 === QUIZ_SKILLS.length && ''}`}>
                        <img className='w-[1.25rem] h-[1.25rem] mr-[0.625rem]' src={ICON} alt={TEXT} />
                        <div className='text-[1.125rem] font-aspira550'>{TEXT}</div>
                      </div>
                    ))}
                    {
                      course.timeEstimation ? (
                        <div key='CourseTime' className='flex w-[15.188rem] sm:mt-[2.3rem]'>
                          <img className='w-[1.25rem] h-[1.25rem] mr-[0.625rem]' src={CLOCK_ICON} alt='CourseTimeer' />
                          <div className='text-[1.125rem] font-aspira550'>{calculateTime(course.timeEstimation!)}</div>
                        </div>
                      ) : null
                    }
                  </div>
                )}
            </div>

            <div className='absolute max-sm:left-6 max-sm:w-[19.438rem] bottom-6 right-6'>
              <div>
                {
                  enrollment && (enrollment.completed)
                    ? (
                      <div className='flex gap-[0.75rem] max-sm:flex-col'>
                        <SecondaryButton
                          onClick={() => getCourseVersionStructure(enrollment.course_version_id)}
                          variant='outlined'
                          className='max-sm:w-[19.438rem]'
                        >
                          {PRACTICE}
                        </SecondaryButton>
                        <SecondaryButton
                          onClick={() => getCertificate()}
                          className='max-sm:w-[19.438rem]'
                          disabled={loadingCert}
                          isLoading={loadingCert}
                          variant='default'
                          style={{ padding: '0.625rem 1.5rem' }}
                        >
                          {GET_CERTIFICATE}
                        </SecondaryButton>
                      </div>
                    )
                    : (
                      <SecondaryButton
                        onClick={() => (enrollment ? getCourseVersionStructure(enrollment.course_version_id) : createCourseEnrollment(course!.current_course_version_id))}
                        variant='default'
                        style={{ padding: '0.625rem 1.5rem' }}
                        className='max-sm:w-[19.438rem]'
                      >
                        {getBlackButtonText()}
                      </SecondaryButton>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default CourseCard;
