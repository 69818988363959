import React, { useEffect, useRef, useState } from 'react';
import { clear } from 'idb-keyval';
import { logout } from '@chegg/one-auth-sdk';
import { useNavigate } from 'react-router-dom';
import { NAVBAR_OPTIONS, DEVELOPMENT, NAV_LINK_NAMES } from '../../constants';
import { AUTHENTICATION } from '../../pages/route_links';
import profileImage from '../../assets/images/profile-menu.svg';
import profileImageGrey from '../../assets/images/profile-menu-grey.svg';

const NavOptions = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref: any = useRef();

  const { LOGOUT, HELP } = NAV_LINK_NAMES;

  const handleLogout = async () => {
    await clear();
    if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) localStorage.removeItem('token');
    await logout().then(() => navigate(AUTHENTICATION));
  };

  const handleClick = (linkName: string) => {
    switch (linkName) {
      case LOGOUT:
        handleLogout();
        break;
      case HELP:
        window.location.replace('https://fkuo1wtze66.typeform.com/to/w2K7aTqL');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref && ref.current) {
        if (!ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <div>
      <div>
        <div className='px-2 cursor-pointer' onClick={() => setIsOpen(!isOpen)} aria-hidden>
          {isOpen
            ? <img src={profileImageGrey} alt='grey-profile-menu' />
            : <img src={profileImage} alt='profile-menu' className='relative fill-[#eeeef1]' />}
        </div>

        {isOpen && (
        <div className='py-[0.5rem] px-[0.938rem] absolute right-[4%] min-w-[10.188rem] h-auto rounded-[0.5rem] shadow-navOptionPopup bg-[#FFF]' ref={ref}>
          {NAVBAR_OPTIONS.map(({ link, icon }) => (
            <div
              key={link}
              className='flex cursor-pointer py-[0.625rem] pr-[0.06rem] items-start'
              onClick={() => handleClick(link)}
              aria-hidden
            >
              <img src={icon} alt='logout' className='h-[1.875rem] w-[1.875rem]' />
              <div className='whitespace-nowrap text-[1rem] font-aspira550 font-gray-900 pt-[0.281rem] pb-[0.156rem] pl-[0.75rem]'>{link}</div>
            </div>
          ))}
        </div>
        )}
      </div>
    </div>
  );
};

export default NavOptions;
