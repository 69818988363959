import React from 'react';
import { BACK_TO_DASHBOARD, ORANGE_ARROW_ICON as orangeArrowIcon } from '../../constants';

interface INavigation {
  navigation: () => void;
}

const BackToDashboard = ({ navigation }: INavigation) => (
  <div
    className='text-[0.875rem] text-orange font-medium leading-[1.05rem] flex items-center cursor-pointer'
    onClick={() => navigation()}
    aria-hidden
  >
    <img src={orangeArrowIcon} alt='back-button' />
    <div className='ml-0.5'>{BACK_TO_DASHBOARD}</div>
  </div>
);

export default BackToDashboard;
