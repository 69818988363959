import { Buffer } from 'buffer';
import Cookies from 'js-cookie';
import axios from 'axios';
import { DEVELOPMENT, METHOD_OPTIONS } from '../constants';
import { ICourse } from '../interfaces';
import { BACKEND_ROUTES } from './backendRoutes';

const { GET_CERTIFICATES } = BACKEND_ROUTES;

export const downloadCertificate = async (course: ICourse) => {
  let token;
  let access_token;
  if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) token = localStorage.getItem('token');
  else token = Cookies.get('id_token');
  if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) access_token = localStorage.getItem('token');
  else access_token = Cookies.get('access_token');
  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}${GET_CERTIFICATES}`,
    { course_id: course.id },
    {
      method: METHOD_OPTIONS.POST,
      headers: { Authorization: `Bearer ${access_token}`, Chegg_token: `Bearer ${token}` },
      responseType: 'arraybuffer',
    },
  );
  const buffer = Buffer.from(res.data, 'binary').toString('base64');
  const anchor = document.createElement('a');
  anchor.href = `data:${res.headers['content-type']};base64,${buffer}`;
  anchor.download = `${course.name} certificate.png`;
  anchor.click();

  return `data:${res.headers['content-type']};base64,${buffer}`;
};
