import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';
import { ICourse } from '../../interfaces';

type State = {
  courses: ICourse[]
}

type Action = {
  setVisibleCourses: (courses: State['courses']) => void
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => (await get(name)) || null,
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

export const visibleCoursesStore = create<State & Action>()(
  persist(
    // eslint-disable-next-line no-shadow
    (set) => ({
      courses: [],
      setVisibleCourses: (visibleCourses) => set(() => ({ courses: visibleCourses })),
    }),
    {
      name: 'courses',
      storage: createJSONStorage(() => storage),
    },
  ),
);
