import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '@chegg-ui/button';
import {
  ICourse, ICurriculum, IEnrollment, IExtendedEnrollment, IModule, IMyCourseStructure, IProgress,
} from '../../interfaces';
import { Carousel, LastStudied } from '../index';
import { percentage, structureMyProgramPage } from '../../utils';
import { STATIC_DASHBOARD_STRINGS, STATIC_SKILLS_CARD_STRINGS } from '../../constants';
import getStartedCards from '../../assets/images/get-started-cards.svg';
import { courseStructureStore } from '../../store/CourseStructure';

interface IProps {
    enrollments: IEnrollment[];
    courses: ICourse[];
    lastStudied?: IEnrollment;
    lastStudiedMod?: IModule;
    lastStudiedCurriculum?: ICurriculum;
    handleDashView: () => void;
    extendedEnrollment: IExtendedEnrollment | undefined;
}

const MyCourses = ({
  enrollments, courses, handleDashView, lastStudied, lastStudiedMod, lastStudiedCurriculum, extendedEnrollment,
}: IProps) => {
  const [restructuredCourses, setRestructuredCourses] = useState<IMyCourseStructure>();
  const [structure] = courseStructureStore((state) => [state.structure]);

  const {
    LEARNING_NOT_STARTED, WELCOME_TEXT_NEW_USER, GET_STARTED,
  } = STATIC_DASHBOARD_STRINGS;

  useEffect(() => {
    async function initiateStructure() {
      if (enrollments && courses) {
        const restructure = structureMyProgramPage(courses, enrollments);
        if (extendedEnrollment || restructure.In_Progress!.length === 1) {
          delete restructure.In_Progress;
        }
        setRestructuredCourses(restructure);
      }
    }

    initiateStructure();
  }, [enrollments, courses]);

  const calculateCompletionPercentage = (progress: IProgress[]) => {
    let completedScreens = 0;
    let totalScreens = 0;
    progress.forEach((module: IProgress) => {
      const { completed_screens, total_screens } = Object.values(module)[0];
      completedScreens += completed_screens;
      totalScreens += total_screens;
    });
    return percentage(completedScreens, totalScreens);
  };

  return (
    <div>
      <div>
        {enrollments.length && !enrollments.every(enrollment => enrollment.completed) ? (
          <div>
            <div className='mb-[1.5rem] mt-[2.5rem] text-grey text-[1.875rem] font-semibold'>{STATIC_SKILLS_CARD_STRINGS.IN_PROGRESS}</div>
            <LastStudied
              course={lastStudied!.course}
              progress={calculateCompletionPercentage(lastStudied!.progress)}
              enrollment={lastStudied!}
              lastStudiedMod={lastStudiedMod!}
              lastStudiedCurriculum={lastStudiedCurriculum!}
              programStructure={structure}
            />
          </div>
        ) : (
          <div>
            {!enrollments.length && (
              <div className='flex flex-col items-center'>
                <img src={getStartedCards} alt='getStartedCards' className='w-[5.725rem] h-[10rem]' />
                <div className='mt-[2rem] font-aspira700 text-[2.25rem] max-lg:text-[1.5rem] max-lg:w-[95%] flex justify-center text-center'>{LEARNING_NOT_STARTED}</div>
                <div className='mt-[0.5rem] font-aspira text-grey text-[1.5rem] max-lg:text-[1rem] mb-[2rem] text-center'>{WELCOME_TEXT_NEW_USER}</div>
                <SecondaryButton
                  width='9.563rem'
                  onClick={() => handleDashView()}
                  variant='outlined'
                >
                  {GET_STARTED}
                </SecondaryButton>
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        {extendedEnrollment ? (
          <div className='mt-[2.75rem]'>
            <LastStudied
              course={extendedEnrollment.enrollment.course}
              enrollment={extendedEnrollment.enrollment}
              lastStudiedMod={extendedEnrollment.module}
              lastStudiedCurriculum={extendedEnrollment.courseCurriculum}
              progress={calculateCompletionPercentage(extendedEnrollment.enrollment.progress)}
              programStructure={extendedEnrollment.structure}
            />
          </div>
        ) : ''}
      </div>

      <div className={!enrollments.length ? 'mt-[3.5rem]' : ''}>
        {restructuredCourses && (Object.entries(restructuredCourses).map(([key, values], index) => (
          <div key={key} className={index === (Object.entries(restructuredCourses).length - 1) ? 'mb-[4.188rem]' : ''}>
            {values.length ? (
              <Carousel cards={values} name={key} />
            ) : ''}
          </div>
        )))}
      </div>
    </div>
  );
};

export default MyCourses;
