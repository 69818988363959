import React from 'react';
import { DASH_TYPES } from '../../constants';
import DashButton from '../DashButton';

interface IProps {
  handleDashView: (view: string) => void;
  selectedView: string;
}

const DashSidebar = ({ handleDashView, selectedView }: IProps) => (
  <div
    className='max-xl:hidden h-full w-[17%] flex flex-col items-center bg-white border-r border-r-1 border-[#E2E1E6] pt-[1.5rem] fixed'
  >
    {DASH_TYPES.map(({ name, icon, selectedIcon }) => (
      <div key={name} aria-hidden onClick={() => handleDashView(name)} className='w-full flex justify-center h-[3rem] mb-[1rem]'>
        <DashButton
          selectedView={selectedView}
          buttonName={name}
          icon={icon}
          selectedIcon={selectedIcon}
        />
      </div>
    ))}
  </div>
);

export default DashSidebar;
