import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuizOptions } from '../index';
import { IQuiz } from '../../interfaces';
import {
  CHANGE_SCREEN_OPTION, NEXT_BTN, QUIZ_SUBMISSION_OPTIONS, QUIZ_TYPES, STATIC_QUIZ_STRINGS,
} from '../../constants';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';

interface IProps {
  quiz: IQuiz;
  isExplanationViewed: boolean;
  handleChangeScreen: (context: string) => void;
  handleFlipCard: () => void;
  userAnswered: (boolean: boolean) => void;
}

const Quiz = ({
  quiz, isExplanationViewed, handleChangeScreen, handleFlipCard, userAnswered,
}: IProps) => {
  const { moduleId, screenNum } = useParams();
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [quizStatus, setQuizStatus] = useState<string>('');
  const [explanationSeen, setExplanationSeen] = useState<boolean>(false);

  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);
  const [hasInitialScroll, setHasInitialScroll] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setIsScrollAtBottom(isAtBottom);
    }
  };

  // Function to check initial scroll position
  const checkInitialScroll = () => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setHasInitialScroll(!isAtBottom);
    }
  };

  // Add scroll event listener and check initial scroll when the component mounts
  useEffect(() => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      checkInitialScroll();
    }

    // Remove the scroll event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const { SHOW_EXPLANATION } = STATIC_QUIZ_STRINGS;
  const { IS_SELECTED } = QUIZ_SUBMISSION_OPTIONS;
  const { MULTIPLE_CHOICE, SURVEY } = QUIZ_TYPES;

  const { type, material: { correctAnswer, answers, question } } = quiz;

  const handleQuizSubmission = (status: string) => {
    switch (status) {
      case IS_SELECTED:
        if (type === SURVEY) {
          setTimeout(() => {
            handleFlipCard();
          }, 500);
        }
        setQuizStatus(IS_SELECTED);
        break;
      case NEXT_BTN:
        if (isExplanationViewed) handleFlipCard();
        handleChangeScreen(CHANGE_SCREEN_OPTION.FORWARD_ARROW);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setUserAnswer('');
    setQuizStatus('');
    setExplanationSeen(false);
  }, [moduleId, screenNum]);

  useEffect(() => {
    if (userAnswer && !quizStatus) handleQuizSubmission(IS_SELECTED);
    if (userAnswer) {
      userAnswered(true);
    } else {
      userAnswered(false);
    }
  }, [userAnswer]);

  useEffect(() => {
    if (isExplanationViewed) {
      setTimeout(() => {
        if (type === SURVEY) {
          setUserAnswer(userAnswer);
        } else {
          setUserAnswer(correctAnswer);
          setExplanationSeen(true);
        }
        setQuizStatus(IS_SELECTED);
      }, 1000);
    }
  }, [isExplanationViewed]);

  return (
    <div className={`w-full h-full relative ${isExplanationViewed ? 'hidden' : ''} max-lg:flex max-lg:justify-center max-lg:w-screen`}>
      <div id='quiz' className='bg-white lg:shadow-lastStudiedBox max-lg:mt-[1rem] rounded-[0.5rem] h-full lg:w-[40vw] sm:w-[90vw] max-sm:w-[100vw] p-[3.5rem] max-sm:p-[1rem] lg:overflow-y-auto right-player'>
        <div className='max-lg:hidden fixed top-0 left-1 w-[97%] h-[3.75rem] max-sm:h-[2.25rem] bg-white z-50'>
          <div className='absolute bottom-[0.25rem] w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem] ml-[3rem] max-sm:ml-[1rem]' />
        </div>

        <div className='lg:hidden w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem]' />

        <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{question}</div>
        <QuizOptions
          quizType={type}
          quizStatus={quizStatus}
          answers={answers}
          correctAnswer={correctAnswer}
          handleClick={setUserAnswer}
          isExplanationViewed={explanationSeen}
        />

        {hasInitialScroll && !isScrollAtBottom
          ? <div className='fixed z-[70] bottom-[5.125rem] left-0 h-[4.5rem] w-full bg-gradient-to-b from-transparent to-white' />
          : null}

        <div className='lg:fixed lg:bottom-0 cursor-pointer bg-white lg:h-[5.125rem] lg:w-[85%]'>
          {type === MULTIPLE_CHOICE && (
          <div
            className='flex items-center cursor-pointer'
            onClick={async () => {
              handleFlipCard();
              await useClickstreamInteraction('click', 'button', 'show answer', 'Show explanation');
            }}
            aria-hidden
          >
            <div className='text-[#4727C3] text-[1.25rem] font-aspira700 underline underline-offset-4'>{SHOW_EXPLANATION}</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
