// eslint-disable-next-line import/no-unresolved
import { ClientInterface } from '@chegg/rio-sdk-web/dist/types/types';
import { getClient, SessionData, StateUpdateData } from '@chegg/rio-sdk-web';
// eslint-disable-next-line import/no-unresolved
import { ViewExperience } from '@chegg/rio-sdk-web/dist/types/baseEventWeb';
import { EventSchemas } from '../eventSchemas';

let rioClient: ClientInterface<EventSchemas>;

export function getRioClient() {
  rioClient = getClient<EventSchemas>();
  return rioClient;
}

export function getRioConfig() {
  const config: SessionData = {
    endpoint: process.env.REACT_APP_RIO_ENDPOINT!,
    appId: 'skills',
    property: 'chegg',

    authState: 'unknown',
    view: {
      property: 'skills',
      experience: 'sfs' as ViewExperience,
      view_name: '',
    },
  };
  return config;
}

export async function sendRioEvent(type: EventSchemas['type'], data: EventSchemas['data']) {
  try {
    await getRioClient().sendEvent({ type, data, eventVersion: 3 });
  } catch (error) {
    console.error('failed to send rio event');
  }
}

export function updateRioState(state: StateUpdateData) {
  getRioClient().updateState(state);
}
