import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD } from '../../pages/route_links';
import PlayerProgressBar from '../PlayerProgressBar';
import { lastStringCharToNumber } from '../../utils';
import homeIcon from '../../assets/images/player-home-btn.svg';
import { IModuleWithoutScreen } from '../../interfaces';
import { courseStructureStore } from '../../store/CourseStructure';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';
import playerArrow from '../../assets/images/player-topbar-arrow.svg';

interface IProps {
  denominator: number;
  handleChangeModule: (moduleToView: number) => void;
  isLeftOption: boolean | undefined;
  isRightOption: boolean | undefined;
  scrollBehavior: boolean;
}

const PlayerTopBar = ({
  denominator,
  handleChangeModule,
  isLeftOption,
  isRightOption,
  scrollBehavior,
}: IProps) => {
  const { screenNum, moduleId } = useParams();
  const navigate = useNavigate();

  const structure = courseStructureStore((state) => state.structure);

  const handleModuleProgressBar = (index: number) => {
    const viewingModule = +moduleId![moduleId!.length - 1];

    if (index === viewingModule) return ((+screenNum! + 1) / denominator) * 100;
    if (index < viewingModule) return 100;
    if (index > viewingModule) return 0;
    return null;
  };

  return (
    <div>
      {structure && structure.length && (
      <div className={`${scrollBehavior ? 'fixed' : 'max-lg:absolute lg:fixed'} z-10 top-0 left-0 right-0 h-[5.688rem] border border-b-1 max-sm:border-b-2 border-b-[#E2E1E6] bg-white`}>
        <div
          className='absolute left-4 h-full flex cursor-pointer items-center'
          aria-hidden
          onClick={async () => {
            await useClickstreamInteraction('click', 'button', 'exit player', 'x');
            navigate(DASHBOARD);
          }}
        >
          <div className='w-[3rem] h-[3rem] rounded-full border border-1 border-[#302C41] flex justify-center items-center'>
            <img className='h-[1.5rem] w-[1.5rem]' src={homeIcon} alt='home' />
          </div>
        </div>

        <div className='flex flex-col h-full items-center justify-center max-sm:mr-[1rem]'>
          <div className='flex flex-col justify-center items-center max-sm:items-end w-full'>
            <div className='flex items-center justify-center'>
              {
                !isLeftOption && (
                <div aria-hidden onClick={() => handleChangeModule(+moduleId!.split('-')[1] - 1)} className='max-sm:hidden cursor-pointer'>
                  <img
                    src={playerArrow}
                    alt='module-nav'
                    className='w-[1.5rem] h-[1.5rem] mr-[0.5rem]'
                  />
                </div>
                )
              }

              {
                structure.map(({ id, displayName }: IModuleWithoutScreen, index: number) => (
                  <PlayerProgressBar
                    denominator={denominator}
                    key={id}
                    progress={{ percents: handleModuleProgressBar(index + 1)!, subTitle: displayName }}
                    progressBarIndex={index}
                    handleChangeModule={handleChangeModule}
                    currentPage={lastStringCharToNumber(moduleId!)}
                  />
                ))
              }

              {
                !isRightOption && (
                <div aria-hidden onClick={() => handleChangeModule(+moduleId!.split('-')[1] + 1)} className='max-sm:hidden cursor-pointer'>
                  <img
                    src={playerArrow}
                    alt='module-nav'
                    className='rotate-180 w-[1.5rem] h-[1.5rem] ml-[0.5rem]'
                  />
                </div>
                )
              }
            </div>
            <div className='max-sm:hidden text-[#302C41] font-aspira550 text-[1.125rem] mt-[0.3rem]'>{structure.length && structure[+moduleId!.split('-')[1] - 1].displayName}</div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default PlayerTopBar;
