import React from 'react';
import remove from '../../assets/images/circle-x.svg';

interface IProps {
    name: string;
    removeTag: () => void;
}
const Tag = ({ name, removeTag }: IProps) => (
  <div
    className='rounded-[1.5rem] border border-1 border-[#928E9F] h-[2rem] bg-[#EEEEF1] flex justify-between items-center cursor-pointer'
    onClick={() => removeTag()}
    aria-hidden
  >
    <div className='pl-[0.75rem] mr-[1rem] font-aspira550 text-[#302C41]'>{name}</div>
    <img src={remove} alt='remove' className='pr-[0.5rem]' />
  </div>
);

export default Tag;
