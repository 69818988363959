import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as braze from '@braze/web-sdk';
import { useExitIntent } from 'use-exit-intent';
import { AUTHENTICATION } from '../../pages/route_links';
import { DEVELOPMENT } from '../../constants';
import { decodeIdToken } from '../../utils';

const PrivateRoutes = () => {
  const [userToken, setUserToken] = useState<any>();

  const { registerHandler, resetState } = useExitIntent({
    desktop: {
      delayInSecondsToTrigger: 1,
    },
    mobile: {
      delayInSecondsToTrigger: 1,
      triggerOnIdle: false,
    },
  });

  registerHandler({
    id: 'openModal',
    handler: () => {
      braze.logCustomEvent('exit intent');
      setTimeout(() => {
        resetState();
        Cookies.set('exit-intent', 'false');
      }, 300000);
    },
  });

  let token: any;
  if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) token = localStorage.getItem('token');
  else token = Cookies.get('id_token');

  useEffect(() => {
    if (token && (!userToken || token.sub !== userToken.sub)) setUserToken(token);
  }, [token]);

  useEffect(() => {
    async function initiateUserSession() {
      if (userToken && userToken.sub) {
        const decodedToken = await decodeIdToken(token);
        braze.changeUser(decodedToken.sub);

        braze.subscribeToInAppMessage((inAppMessage) => {
          if (inAppMessage instanceof braze.ControlMessage) {
            return braze.showInAppMessage(inAppMessage);
          }

          braze.showInAppMessage(inAppMessage);
          return null;
        });
      }

      braze.openSession();
    }

    initiateUserSession();
  }, [userToken]);

  return (
    token ? (
      <Outlet />
    ) : <Navigate to={AUTHENTICATION} />
  );
};

export default PrivateRoutes;
