import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { refreshToken } from '@chegg/one-auth-sdk';
import { http } from '../services/http.service';
import { IAxiosConfig } from '../interfaces';
import { DEVELOPMENT, METHOD_OPTIONS } from '../constants';

const useAxiosFunction = () => {
  const [response, setResponse] = useState<any>({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState<AbortController>();

  const axiosFetch = async (configObj: IAxiosConfig) => {
    const {
      method,
      url,
      data,
    } = configObj;

    let token;
    if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) token = localStorage.getItem('token');
    else token = Cookies.get('access_token');
    if (!token) await refreshToken();

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await http[method](
        url,
        method === METHOD_OPTIONS.GET ? { params: data } : { ...data },
      );
      if (res?.data?.error) setError(res.data.error.message);
      else setResponse({ url, data: res.data, status: res.status });
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err.message);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => () => controller && controller.abort(), [controller]);

  return [response, error, loading, axiosFetch];
};

export default useAxiosFunction;
