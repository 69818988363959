import React, { useState } from 'react';
import expandIcon from '../../assets/images/expand-image.svg';
import closeIcon from '../../assets/images/closeImage.svg';
import { Portal } from '../index';

interface IProps {
  src: string;
  alt?: string;
}

const PlayerImage = ({
  src, alt,
}:IProps) => {
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div>
      <div className='w-full flex justify-center items-center relative'>
        <img
          className='rounded-[0.75rem] max-w-full'
          src={src}
          alt={alt}
          onLoad={() => setImageLoaded(true)}
          id='contentful-image'
        />

        {imageLoaded
          ? (
            <div
              className='absolute bg-white right-[1.5rem] bottom-[1.5rem] h-[3rem] w-[3rem] rounded-full border border-1 border-grey flex justify-center items-center'
              aria-hidden
              onClick={() => setIsImageExpanded(true)}
            >
              <img src={expandIcon} alt='expand' className='h-[1.25rem] w-[1.25rem] cursor-pointer' />
            </div>
          ) : null}
      </div>

      <Portal isOpen={isImageExpanded}>
        <div
          className='z-[1024] fixed right-0 left-0 top-0 w-full h-full bg-black bg-opacity-40'
          onClick={() => setIsImageExpanded(false)}
          aria-hidden
        >
          <div className='cursor-pointer flex h-full w-full justify-center items-center'>
            <div className='relative'>
              <img
                className='block sm:max-w-[70vw] sm:min-w-[40vw] sm:min-h-[40vw] sm:max-h-[52vw] max-sm:w-full sm:rounded-[0.75rem]'
                src={src}
                alt='player'
              />
              <div
                className='absolute right-3 bottom-5 h-[3rem] w-[3rem] rounded-full'
                aria-hidden
                onClick={() => setIsImageExpanded(false)}
              >
                <img src={closeIcon} alt='closeImage' className='h-[4rem] w-[4rem] cursor-pointer' />
              </div>
            </div>
          </div>
        </div>
      </Portal>
    </div>
  );
};

export default PlayerImage;
