import React, { useEffect, useRef, useState } from 'react';
import dropArrow from '../../assets/images/drop-arrow.svg';
import whiteCheck from '../../assets/images/white-check-icon.svg';
import { DROP_BUTTONS } from '../../constants';

interface IProps {
    defaultOption: string;
    options: string[];
    selectedOptions: string[];
    handleSelected: (tempSelected: string[]) => void;
}

const Dropdown = ({
  defaultOption, selectedOptions, options, handleSelected,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelected, setTempSelected] = useState([...selectedOptions]);
  const ref: any = useRef();

  const { CLEAR, DONE } = DROP_BUTTONS;

  const handleSelectedOption = (option: string) => {
    if (tempSelected.includes(option)) {
      setTempSelected(tempSelected.filter(selected => selected !== option));
    } else {
      setTempSelected((prevState) => [...prevState, option]);
    }
  };

  const handleOpeningDrop = () => {
    if (!isOpen) setTempSelected(selectedOptions);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref && ref.current) {
        if (!ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <div className='relative' ref={ref}>
      <div
        className={`cursor-pointer bg-white p-[1rem] flex items-center justify-between w-[13.75rem] h-[3rem] rounded-[0.25rem] border ${isOpen ? 'border-2 border-[#302C41]' : 'border-1 border-[#534F63]'}`}
        onClick={() => handleOpeningDrop()}
        aria-hidden
      >
        <div className='text-[#302C41] font-aspira400'>{defaultOption}</div>
        <img src={dropArrow} alt='open-closed-drop' className={isOpen ? 'rotate-180' : ''} />
      </div>

      {isOpen && (
        <div className='absolute top-[3.625rem] z-[110] rounded-[0.5rem] w-[14.5rem] bg-white border border-1 border-[#CAC8D1] shadow-lastStudiedBox'>
          <div>
            {options.sort().map(option => (
              <div key={option} className='w-full p-[1rem] flex cursor-pointer' onClick={() => handleSelectedOption(option)} aria-hidden>
                <div className={`mr-[0.75rem] w-[1.5rem] h-[1.5rem] rounded-[0.25rem] ${tempSelected.includes(option) ? 'bg-[#EB7100] flex justify-center items-center' : 'border border-1 border-[#534F63] bg-white'}`}>
                  <img src={whiteCheck} alt='small-white-arrow' className={`w-[1rem] h-[1rem] ${!tempSelected.includes(option) ? 'hidden' : ''}`} />
                </div>
                <div className='text-[#302C41] font-aspira400'>{option}</div>
              </div>
            ))}
          </div>

          <div className='w-full flex items-center justify-between p-[1rem] border-t border-t-1 border-t-[#CAC8D1]'>
            <div
              className='cursor-pointer underline underline-offset-2 text-[#302C41] font-aspira550 text-[0.875rem]'
              onClick={() => {
                handleSelected([]);
                setTempSelected([]);
                setIsOpen(false);
              }}
              aria-hidden
            >
              {CLEAR}
            </div>
            <div
              className='cursor-pointer bg-[#302C41] h-[2rem] w-[4.188rem] text-[0.875rem] font-aspira550 text-white rounded-[6.25rem] flex justify-center items-center'
              onClick={() => {
                handleSelected(tempSelected);
                setTempSelected([]);
                setIsOpen(false);
              }}
              aria-hidden
            >
              {DONE}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
