import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '@chegg-ui/button';
import { IEnrollment } from '../../interfaces';
import { CourseCard } from '../index';
import { DASHBOARD_VIEW_TYPES, EMPTY_COMPLETED_TAB_STRINGS } from '../../constants';
import emptyState from '../../assets/images/completed-tab-empty.svg';

interface IProps {
    enrollments: IEnrollment[]
    handleDashView: () => void;
}

const CompletedPrograms = ({ enrollments, handleDashView }: IProps) => {
  const [sortedEnrollments, setSortedEnrollments] = useState<IEnrollment[]>([]);

  const { NO_COMPLETED, NO_COMPLETED_SUB_TITLE, GET_STARTED } = EMPTY_COMPLETED_TAB_STRINGS;

  useEffect(() => {
    if (enrollments) {
      const completedEnrollments = enrollments.filter(enrollment => enrollment.completed);
      completedEnrollments.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      setSortedEnrollments(completedEnrollments);
    }
  }, [enrollments]);

  return (
    <div className='min-h-full mt-[2.5rem]'>
      {sortedEnrollments && sortedEnrollments.length ? (
        <div>
          <div className='mb-[1.5rem] mt-[2.5rem] text-grey text-[1.875rem] font-aspira700'>{DASHBOARD_VIEW_TYPES.COMPLETED}</div>
          <div className='flex flex-wrap'>
            {sortedEnrollments.map((enrollment: IEnrollment) => (
              <div key={enrollment.id} className='mb-[2.5rem]'>
                <CourseCard
                  key={enrollment.id}
                  course={enrollment.course}
                  enrollment={enrollment}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='w-full h-full flex flex-col items-center mt-[3rem]'>
          <img src={emptyState} alt='empty-tab' className='w-[16.5rem] h-[16.5rem]' />
          <div className='mt-[2rem] mb-[0.5rem] font-aspira700 text-grey text-[1.75rem]'>{NO_COMPLETED}</div>
          <div className='font-aspira400 text-[#474358] text-[1.375rem] mb-[2rem] text-center'>{NO_COMPLETED_SUB_TITLE}</div>
          <SecondaryButton
            width='9.563rem'
            onClick={() => handleDashView()}
            variant='outlined'
          >
            {GET_STARTED}
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

export default CompletedPrograms;
