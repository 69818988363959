import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import lottie from 'lottie-web';
import animationData from '../../assets/images/confetti.json';

const Confetti = () => {
  useEffect(() => {
    const container = document.getElementById('lottie-container');

    if (container) {
      lottie.loadAnimation({
        container,
        animationData,
        loop: true, // Set loop to true or false as needed
        autoplay: true, // Set autoplay to true or false as needed
      });
    }
  }, []);

  return <div className='w-full h-full' id='lottie-container' />;
};

export default Confetti;
