import { useEffect } from 'react';
import { sendRioEvent } from '../indes';

export function useClickstreamModuleNotifications(course: string, moduleStarted: string) {
  useEffect(() => {
    if (moduleStarted) {
      const modStartedMessageDetail = `${course}/${moduleStarted}`;
      sendRioEvent('clickstream_notifications', {
        notifications: {
          type: 'info',
          message_name: 'module started',
          message_detail: modStartedMessageDetail,
        },
      });
    }
  }, [moduleStarted]);
}
