import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LazyLoadImage } from 'react-lazy-load-image-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-lazy-load-image-component/src/effects/blur.css';
import { SecondaryButton } from '@chegg-ui/button';
import {
  ICourse, ICurriculum, IEnrollment, IModule, IModuleWithoutScreen, IScreen, ITimeEstimations,
} from '../../interfaces';
import { PlayerContent, ModuleCard, CardProgramProgress } from '../index';
import {
  STATIC_SKILLS_CARD_STRINGS,
} from '../../constants';
import { courseStructureStore } from '../../store/CourseStructure';
import { PLAYER } from '../../pages/route_links';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';
import courseCardImage from '../../assets/images/card-bg.jpg';
import { downloadCertificate } from '../../services/downloadCertifiacte';

interface IProps {
    course: ICourse;
    enrollment: IEnrollment;
    lastStudiedMod: IModule;
    lastStudiedCurriculum: ICurriculum;
    progress: number;
    programStructure: IModuleWithoutScreen[];
}

const LastStudied = ({
  course, enrollment, lastStudiedMod, lastStudiedCurriculum, progress, programStructure,
}: IProps) => {
  const navigate = useNavigate();
  const [loadingCert, setLoadingCert] = useState(false);
  const [isStructureExist, setIsStructureExist] = useState(false);
  const [timeEst, setTimeEst] = useState<ITimeEstimations[]>();
  const [isShowFullSyllabus, setIsShowFullSyllabus] = useState<boolean>(false);
  const [initialStructure, setInitialStructure] = useState<IModuleWithoutScreen[]>([]);
  const [setCourseStructure] = courseStructureStore((state) => [state.setCourseStructure]);

  const {
    CONTINUE_LEARNING, NEXT_STEP, FULL_SYLLABUS, GET_CERTIFICATE, CLOSE_SYLLABUS, PRACTICE,
  } = STATIC_SKILLS_CARD_STRINGS;

  useEffect(() => {
    if (programStructure && !initialStructure.length) setInitialStructure(programStructure);
  }, [programStructure]);

  useEffect(() => {
    if (lastStudiedCurriculum) {
      const moduleTimeEstimations: ITimeEstimations[] = [];
      lastStudiedCurriculum.modules.forEach(({ id, screens }: {id: string, screens: IScreen[]}) => {
        if (enrollment && +enrollment.current_module.slice(-1) > +id.slice(-1)) {
          moduleTimeEstimations.push({ [id]: 0 });
        } else if (enrollment && +enrollment.current_module.slice(-1) === +id.slice(-1)) {
          let completedSum = 0;
          let fullModSum = 0;
          screens.forEach(({ timeEstimation }, index) => {
            if (index < +enrollment.current_screen) completedSum += timeEstimation!;
            fullModSum += timeEstimation!;
          });
          moduleTimeEstimations.push({ [id]: fullModSum - completedSum });
        } else {
          let fullModSum = 0;
          screens.forEach(({ timeEstimation }) => { fullModSum += timeEstimation!; });
          moduleTimeEstimations.push({ [id]: fullModSum });
        }
      });
      setTimeEst(moduleTimeEstimations);
    }
  }, [lastStudiedCurriculum]);

  useEffect(() => {
    if (isStructureExist) {
      if (enrollment && enrollment.completed) {
        navigate(generatePath(PLAYER, {
          courseId: course.id.toString(),
          versionId: enrollment.course_version_id.toString(),
          moduleId: 'module-1',
          screenNum: '0',
        }));
      } else {
        navigate(generatePath(PLAYER, {
          courseId: course.id.toString(),
          versionId: enrollment.course_version_id.toString(),
          moduleId: enrollment.current_module,
          screenNum: enrollment.current_screen,
        }));
      }
    }
  }, [isStructureExist]);

  const getCertificate = async () => {
    useClickstreamInteraction('click', 'button', 'download certificate', 'Download your certificate');
    setLoadingCert(true);
    await downloadCertificate(course);
    setLoadingCert(false);
  };

  return (
    <div>
      <div
        className='max-xl:relative flex max-xl:flex-col w-full shadow-lastStudiedBox bg-white rounded-[0.75rem]'
      >
        <LazyLoadImage
          className={`${!isShowFullSyllabus ? 'h-[395px] xl:min-w-[550px] xl:max-w-[550px]' : 'h-[927px] xl:min-w-[550px] xl:max-w-[550px]'} max-xl:h-[9rem] max-xl:object-cover xl:rounded-l-[0.75rem] max-xl:rounded-t-[0.75rem] max-xl:w-full`}
          src={course.image ? course.image : courseCardImage}
          effect='blur'
          alt='last-studied'
        />

        <div className='xl:relative ml-[1.5rem] pr-[1rem] w-full max-xl:p-0 max-xl:m-0 max-xl:w-[98%] max-xl:mx-2 max-sm:w-full max-sm:m-0 max-sm:px-[1.5rem]'>
          <div>
            <div className='text-grey text-[1.375rem] font-aspira700 mt-[1.5rem] mb-[0.5rem]'>{course.name}</div>
            <div className='text-lightBlueGrey text-[1.125rem] font-aspira400 mb-[1.5rem]'>
              <PlayerContent content={JSON.parse(course.description)} />
            </div>
          </div>

          <div className='flex justify-between'>
            <div className='text-[0.75rem] text-lightBlueGrey font-aspira700 mb-[0.25rem]'>{NEXT_STEP}</div>
            <div
              className='flex cursor-pointer underline text-[#341C8D] text-[0.875rem] font-aspira550'
              aria-hidden
              onClick={() => setIsShowFullSyllabus(!isShowFullSyllabus)}
            >
              {!isShowFullSyllabus ? FULL_SYLLABUS : CLOSE_SYLLABUS}
            </div>
          </div>

          <div className=' max-sm:mb-[3rem] max-xl:mb-[12rem]'>
            {isShowFullSyllabus
              ? (
                <div className='relative'>
                  {initialStructure && initialStructure.map(({ displayName, id, screensLength }, index: number) => (
                    <div className='mb-[0.5rem]' key={id}>
                      <ModuleCard
                        isShowFullSyllabus={isShowFullSyllabus}
                        displayName={displayName ?? ''}
                        screens={screensLength ?? 0}
                        modNumber={index + 1}
                        currentScreen={enrollment.progress[index][id].completed_screens}
                        currentModNumber={+enrollment.current_module.split('-')[1]}
                        timeEst={timeEst ? timeEst[index][id] : 0}
                        completed={!!(enrollment && enrollment.completed)}
                      />
                    </div>
                  ))}
                </div>
              )
              : (
                <ModuleCard
                  isShowFullSyllabus={isShowFullSyllabus}
                  completed={!!(enrollment && enrollment.completed)}
                  displayName={lastStudiedMod.displayName ?? ''}
                  screens={lastStudiedMod.screens.length ?? 0}
                  modNumber={+enrollment.current_module.split('-')[1]}
                  currentScreen={+enrollment.current_screen}
                  timeEst={timeEst ? timeEst[+enrollment.current_module.split('-')[1] - 1][enrollment.current_module] : 0}
                />
              )}
          </div>
          <div className='absolute max-sm:relative bottom-[1.5rem] flex justify-between w-full max-sm:w-full max-xl:w-[98%] max-xl:gap-[1.5rem] max-xl:flex-col xl:items-end'>
            <CardProgramProgress numerator={enrollment && enrollment.completed ? initialStructure.length : +enrollment.current_module.split('-')[1] - 1} denominator={initialStructure.length} />

            <div className='xl:mr-[1rem] max-xl:w-full'>
              <div className='max-xl:w-full'>
                {
                  enrollment && (enrollment.completed || progress === 100)
                    ? (
                      <div className='flex max-xl:justify-end max-sm:justify-center'>
                        <div className='mr-[0.75rem]'>
                          <SecondaryButton
                            onClick={() => {
                              setCourseStructure(initialStructure);
                              setIsStructureExist(true);
                            }}
                            variant='outlined'
                          >
                            <div className='text-[1.25rem] font-aspira700'>
                              {PRACTICE}
                            </div>
                          </SecondaryButton>
                        </div>
                        <SecondaryButton variant='default' onClick={() => getCertificate()} isLoading={loadingCert} disabled={loadingCert}>
                          <div className='text-[1.25rem] font-aspira700'>
                            {GET_CERTIFICATE}
                          </div>
                        </SecondaryButton>
                      </div>
                    )
                    : (
                      <SecondaryButton
                        variant='default'
                        onClick={() => {
                          setCourseStructure(initialStructure);
                          setIsStructureExist(true);
                        }}
                        className='max-xl:w-full'
                      >
                        <div className='text-[1.25rem] font-aspira700'>
                          {CONTINUE_LEARNING}
                        </div>
                      </SecondaryButton>
                    )
                }
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default LastStudied;
