import React from 'react';
import { NavOptions } from '../index';
import logo from '../../assets/images/chegg-logo.svg';

const Navbar = () => (
  <div className='min-h-[6rem] w-full flex justify-center border-b border-b-2 border-b-[#F2F4F7] bg-white fixed top-0 left-0 right-0 z-20'>
    <div className='min-h-full w-[93%] flex justify-between max-xl:justify-center items-center'>
      <img src={logo} alt='logo' />
      <div className='max-xl:absolute max-xl:right-[3.5%]'>
        <NavOptions />
      </div>
    </div>
  </div>
);

export default Navbar;
