import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AUTH_BUTTONS, AUTH_FLOW_QUESTIONS, FINAL_AUTH_FLOW_SCREEN, METHOD_OPTIONS, OTHER,
} from '../../constants';
import arrow from '../../assets/images/skinny-arrow.svg';
import whiteArrow from '../../assets/images/skinny-arrow-white.svg';
import {
  AuthFlowFinalScreen, AuthFlowProgressBar, AuthFlowQuestion, Loader,
} from '../index';
import { DASHBOARD } from '../../pages/route_links';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import { BACKEND_ROUTES } from '../../services/backendRoutes';

const AuthQuestionnaire = () => {
  const navigate = useNavigate();
  const [questionNum, setQuestionNum] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [otherAnswer, setOtherAnswer] = useState<string>('');
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);
  const [hasInitialScroll, setHasInitialScroll] = useState(false);

  const idName = useRef('authScroll');

  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const {
    type, question, image, answers,
  } = AUTH_FLOW_QUESTIONS[questionNum === AUTH_FLOW_QUESTIONS.length ? AUTH_FLOW_QUESTIONS.length - 1 : questionNum];

  const { CONTINUE, SKIP } = AUTH_BUTTONS;

  const setStudentInsights = () => axiosFetch({
    method: METHOD_OPTIONS.POST,
    url: BACKEND_ROUTES.SET_STUDENT_INSIGHT,
    data: { type, question, selectedAnswer: selectedAnswer === OTHER ? `${selectedAnswer}, ${otherAnswer}` : selectedAnswer },
  });

  useEffect(() => {
    const div = document.getElementById(idName.current);
    if (div) div!.scrollTop = 0;
  }, [questionNum]);

  useEffect(() => {
    setSelectedAnswer('');
  }, [response]);

  const handleNextStep = () => {
    if (questionNum === AUTH_FLOW_QUESTIONS.length) {
      navigate(DASHBOARD);
    } else if ((selectedAnswer && selectedAnswer !== OTHER) || (selectedAnswer === OTHER && otherAnswer.length)) {
      setStudentInsights();
      setQuestionNum(questionNum + 1);
    }
  };

  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherAnswer(e.target.value);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const divElement = document.getElementById(idName.current); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setIsScrollAtBottom(isAtBottom);
    }
  };

  // Function to check initial scroll position
  const checkInitialScroll = () => {
    const divElement = document.getElementById(idName.current); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setHasInitialScroll(!isAtBottom);
    }
  };

  // Add scroll event listener and check initial scroll when the component mounts
  useEffect(() => {
    const divElement = document.getElementById(idName.current); // Replace with your actual div's ID
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      checkInitialScroll();
    }

    // Remove the scroll event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className='flex w-full h-full max-xl:justify-center'>
      {loading && !error ? (
        <div className='h-full w-full flex justify-center items-center'>
          <Loader />
        </div>
      )
        : (
          <div className='flex w-full h-full max-xl:justify-center'>
            <div id={idName.current} className='xl:w-[50%] xl:pr-[2rem] max-xl:min-w-[75%] overflow-y-auto picker-scroll'>
              <div className='mt-[7.5rem] flex xl:items-end max-xl:justify-center'>
                <AuthFlowProgressBar questions={AUTH_FLOW_QUESTIONS} currentQuestion={questionNum + 1} />
              </div>

              <div className='mt-[3rem] max-xl:pb-[7rem] xl:pb-[5.5rem]'>
                {
                  questionNum === AUTH_FLOW_QUESTIONS.length
                    ? <AuthFlowFinalScreen />
                    : (
                      <div className='items-center'>
                        <div className='text-[2.25rem] text-grey font-aspira800 w-[90%] max-xl:w-full mb-[3.5rem]'>{question}</div>

                        <div>
                          {answers.map((answer) => <AuthFlowQuestion key={answer} selectedAnswer={selectedAnswer} answer={answer} handleSelection={() => setSelectedAnswer(answer)} />)}
                        </div>

                        <div>
                          {
                            selectedAnswer === OTHER ? (
                              <textarea
                                onChange={(e) => handleTextArea(e)}
                                placeholder='Please Specify'
                                className='rounded-[0.25rem] text-[#302C41] font-aspira400 w-full min-h-[3.5rem] max-h-[6rem] border border-1 border-[#534F63] pb-[1.5rem] pl-[1rem] pt-[1rem] outline-none picker-scroll'
                              />
                            ) : ''
                          }
                        </div>
                      </div>
                    )
                }
              </div>

              <div
                className={`flex fixed xl:h-[6rem] w-[45%] max-xl:items-center max-xl:w-full max-xl:right-0 max-xl:left-0
                ${questionNum === AUTH_FLOW_QUESTIONS.length ? 'md:justify-end max-md:justify-center' : 'justify-between'}
                max-xl:h-[7rem] bottom-0 max-xl:border-t max-xl:border-2-t max-xl:border-[#E2E1E6] max-xl:py-[2rem] max-xl:px-[1rem] bg-white
              `}
              >
                <div
                  className={`${questionNum === AUTH_FLOW_QUESTIONS.length && 'hidden'} cursor-pointer text-[#302C41] text-[1.25rem] underline font-aspira700`}
                  aria-hidden
                  onClick={() => {
                    setQuestionNum(questionNum + 1);
                    setOtherAnswer('');
                    setSelectedAnswer('');
                  }}
                >
                  {SKIP}
                </div>

                <div
                  className={`cursor-pointer w-[10.25rem] h-[3.5rem] rounded-[6.25rem] 
                  ${(selectedAnswer && selectedAnswer !== OTHER) || questionNum === AUTH_FLOW_QUESTIONS.length || (selectedAnswer === OTHER && otherAnswer.length)
                    ? 'bg-orange text-white' : 'bg-buttonGrey text-[#767385]'} flex justify-center items-center text-[1.25rem] font-bold
                  ${questionNum === AUTH_FLOW_QUESTIONS.length && ('max-md:w-[90%]')}
                `}
                  aria-hidden
                  onClick={() => handleNextStep()}
                >
                  {CONTINUE}
                  <img src={(selectedAnswer && selectedAnswer !== OTHER) || questionNum === AUTH_FLOW_QUESTIONS.length || (selectedAnswer === OTHER && otherAnswer.length) ? whiteArrow : arrow} alt='arrow' className='ml-2' />
                </div>
              </div>

              {hasInitialScroll && !isScrollAtBottom
                ? <div className='fixed z-[70] xl:bottom-[6rem] max-xl:bottom-[7rem] left-0 h-[4.5rem] xl:w-[50vw] max-xl:w-full bg-gradient-to-b from-transparent to-white' />
                : null}
            </div>

            <div className='w-[50%] flex justify-end items-center max-xl:hidden'>
              <img className='h-[90vh] min-w-[85%]' src={questionNum === AUTH_FLOW_QUESTIONS.length ? FINAL_AUTH_FLOW_SCREEN.mainImage : image} alt='auth-flow' />
            </div>
          </div>
        )}
    </div>
  );
};
export default AuthQuestionnaire;
