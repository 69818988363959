import React, { useState, useEffect } from 'react';
import { Error, FullDashPanel, Loader } from '../../components';
import {
  METHOD_OPTIONS, PAGE_VIEW_NAMES,
} from '../../constants';
import Navbar from '../../components/Navbar';
import { BACKEND_ROUTES } from '../../services/backendRoutes';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import { visibleCoursesStore } from '../../store/VisableCourses';
import { courseEnrollmentsStore } from '../../store/CourseEnrollments';
import {
  ICourse, ICurriculum, IEnrollment, IModule,
} from '../../interfaces';
import { useClickstreamView } from '../../rio/rioHooks/useClickstreamView';
import { courseStructureStore } from '../../store/CourseStructure';

const Dashboard = () => {
  const { GET_ALL_COURSE_DATA } = BACKEND_ROUTES;
  const { GET } = METHOD_OPTIONS;
  const [enrollments, setEnrollments] = useState<IEnrollment[]>([]);
  const [courses, setCourses] = useState<ICourse[]>();
  const [lastStudied, setLastStudied] = useState<IEnrollment>();
  const [lastStudiedMod, setLastStudiedMod] = useState<IModule>();
  const [lastStudiedCurriculum, setLastStudiedCurriculum] = useState<ICurriculum>();
  const [isNewUser, setIsNewUser] = useState(true);
  const [extendedEnrollment, setExtendedEnrollment] = useState();

  const [setVisibleCourses] = visibleCoursesStore((state) => [state.setVisibleCourses]);
  const [setCourseEnrollments] = courseEnrollmentsStore((state) => [state.setCourseEnrollments]);
  const [setCourseStructure] = courseStructureStore((state) => [state.setCourseStructure]);

  const [response, error, loading, axiosFetch] = useAxiosFunction();

  useClickstreamView(PAGE_VIEW_NAMES.DASHBOARD);

  const getCourseData = () => axiosFetch({
    method: GET,
    url: GET_ALL_COURSE_DATA,
  });

  useEffect(() => {
    async function initiateDash() {
      let unmounted = false;
      if (!unmounted) {
        await getCourseData();
        setCourseStructure([]);
      }

      return () => {
        unmounted = true;
      };
    }

    initiateDash();
  }, []);

  useEffect(() => {
    async function responseHandler() {
      const { data } = response;
      if (data && data.courses && data.enrollments) {
        setCourses(data.courses);
        setVisibleCourses(data.courses);
        if (data.extendedEnrollments && data.extendedEnrollments.length) {
          const lastExtendedStudied = data.extendedEnrollments.sort((a: any, b: any) => new Date(b.enrollment.updatedAt).getTime() - new Date(a.enrollment.updatedAt).getTime())[0];
          setLastStudied(lastExtendedStudied.enrollment);
          setLastStudiedMod(lastExtendedStudied.module);
          setLastStudiedCurriculum(lastExtendedStudied.courseCurriculum);
          setIsNewUser(false);
          setEnrollments(data.enrollments);
          setCourseEnrollments(data.enrollments);
          setCourseStructure(lastExtendedStudied.structure);
          setExtendedEnrollment(data.extendedEnrollments[1]);
        } else if (data.enrollments.length) {
          setLastStudied(data.lastStudied);
          setLastStudiedMod(data.module);
          setLastStudiedCurriculum(data.courseCurriculum);
          setIsNewUser(false);
          setEnrollments(data.enrollments);
          setCourseEnrollments(data.enrollments);
          setCourseStructure(data.structure);
        } else {
          setEnrollments(data.enrollments);
          setCourseEnrollments(data.enrollments);
        }
      }
    }
    responseHandler();
  }, [response]);

  return (
    <div className='bg-white flex flex-col'>
      <Navbar />
      {((loading || !courses) && !error) && (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Loader />
      </div>
      )}

      {error && (<Error />)}

      {courses && !error && !loading && (
      <div className='mt-[6rem] flex flex-grow bg-[#FBFAFB] overflow-auto'>
        <FullDashPanel
          courses={courses}
          enrollments={enrollments!}
          lastStudied={lastStudied}
          lastStudiedMod={lastStudiedMod}
          lastStudiedCurriculum={lastStudiedCurriculum}
          isNewUser={isNewUser}
          extendedEnrollment={extendedEnrollment}
        />
      </div>
      )}
    </div>
  );
};

export default Dashboard;
