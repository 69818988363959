// eslint-disable-next-line import/no-unresolved
import { ViewExperience } from '@chegg/rio-sdk-web/dist/types/baseEventWeb';
import { sendRioEvent, updateRioState } from '../indes';
import {
  ElementName1, ElementText1, ElementType1, InteractionType,
} from '../../eventSchemas';

export const useClickstreamInteraction = (interactionType: InteractionType, type: ElementType1, name: ElementName1, text: ElementText1, view_name?: string) => {
  if (view_name) {
    updateRioState({
      view: {
        view_name,
        experience: 'sfs' as ViewExperience,
        property: 'skills',
      },
    });
  }
  sendRioEvent('clickstream_interaction', {
    interaction: {
      type: interactionType,
      element: {
        type,
        name,
        text,
      },
    },
  });
};
