import React from 'react';
import { PAGE_VIEW_NAMES } from '../../constants';
import { AuthenticationComponent } from '../../components';
import { useClickstreamView } from '../../rio/rioHooks/useClickstreamView';
import auth from '../../assets/images/auth.jpg';
import logo from '../../assets/images/chegg-logo.svg';

const Authentication = () => {
  useClickstreamView(PAGE_VIEW_NAMES.HOME_PAGE);

  return (
    <div className='h-screen w-screen flex justify-center items-center relative'>
      <div className='w-[93vw] h-[95vh] flex items-center'>
        <div className='h-[4.5rem] max-lg:w-[90%] absolute top-0 z-20 bg-white flex items-center'>
          <img src={logo} alt='logo' />
        </div>

        <div className='h-full w-full flex items-center'>
          <div className='w-[50%] max-lg:w-full flex justify-center items-center'>
            <AuthenticationComponent />
          </div>
          <div className='w-[50%] h-full flex justify-center max-lg:hidden'>
            <img className='object-contain' src={auth} alt='auth' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
