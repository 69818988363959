import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';
import { del, get, set } from 'idb-keyval';
import { IEnrollment } from '../../interfaces';

type State = {
  enrollments: IEnrollment[]
}

type Action = {
  setCourseEnrollments: (enrollments: State['enrollments']) => void
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => (await get(name)) || null,
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

export const courseEnrollmentsStore = create<State & Action>()(
  persist(
    // eslint-disable-next-line no-shadow
    (set) => ({
      enrollments: [],
      setCourseEnrollments: (courseEnrollments) => set(() => ({ enrollments: courseEnrollments })),
    }),
    {
      name: 'enrollments',
      storage: createJSONStorage(() => storage),
    },
  ),
);
