import axios from 'axios';
import { SUBSCRIPTION_TYPES } from '../constants';

export const checkSubscribed = async (accessToken: string, idToken: string) => {
  const headers = {
    'content-type': 'application/json',
    'apollographql-client-name': 'one-graph-playground',
    id_token: idToken,
    access_token: accessToken,
  };
  const graphqlQuery = {
    operationName: 'MyOrders',
    query: `query MyOrders {
      myOrders {
        edges {
          node {
            lineItems(input: { filters: { status: ["active", "pendingCancel", "pendingPause"] } }) {
              edges {
                node {
                  catalogItem {
                    offerIds
                  }
                }
              }
            }
          }
        }
      }
    }`,
    variables: {},
  };

  const {
    data: {
      data: {
        myOrders: {
          edges,
        },
      },
    },
  } = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: 'post',
    headers,
    data: graphqlQuery,
  });

  if (edges && edges.length) {
    return edges[0].node.lineItems.edges[0].node.catalogItem.offerIds.some((item: string) => SUBSCRIPTION_TYPES.includes(item));
  }
  return false;
};
