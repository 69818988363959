import BEGINNER_FRIENDLY from '../assets/images/beginner-friendly.svg';
import CERTIFICATE_ICON from '../assets/images/certificate-icon.svg';
import CLOCK_ICON_PNG from '../assets/images/clock-icon.svg';

export const AUTH_ICON = '/images/authentication.jpg';
export const CERTIFICATION_GREY_ICON = '/images/certificate-grey.svg';
export const CERTIFICATION_ORANGE_ICON = '/images/orange-certificate.svg';
export const CERTIFICATION_ICON = '/images/certificate-icon.svg';
export const CHECK_ICON = '/images/check-icon.png';
export const CLOCK_ICON = CLOCK_ICON_PNG;
export const CLOSE_ICON = '/images/close-icon.svg';
export const CLOSE_ORANGE_ICON = '/images/close-orange-icon.svg';
export const ERROR_ICON = '/images/error.png';
export const GIRL_WITH_ICON = '/images/girl-with-laptop.jpg';
export const GREEN_CERTIFICATION_ICON = '/images/green-certificate-icon.svg';
export const LEFT_ARROW_ICON = '/images/left-arrow.svg';
export const LOGOUT_ICON = '/images/logout.svg';
export const HELP_ICON = '/images/help-icon.svg';
export const ORANGE_ARROW_ICON = '/images/orange-arrow-icon.svg';
export const SEARCH_ICON = '/images/search-icon.svg';
export const WHITE_CHECK_ICON = '/images/white-check-icon.svg';
export const GREEN_CHECK_ICON = '/images/green-check-icon.svg';
export const WRONG_ICON = '/images/wrong-icon.svg';
export const ENLARGE_ICON = '/images/enlarge-icon.svg';
export const HOME_ICON = '/images/home_side_bar.svg';
export const LIBRARY_ICON = '/images/library_side_bar.svg';
export const HOME_CHOSEN_ICON = '/images/home_side_bar_chosen.svg';
export const LIBRARY_CHOSEN_ICON = '/images/library_side_chosen.svg';
export const QUESTION_1 = '/images/question-1.jpg';
export const QUESTION_2 = '/images/question-2.jpg';
export const MAIN_FINAL_SCREEN_PIC = './images/main-final-screen-auth.jpg';
export const COMPLETED_GREY = './images/completed-icon.svg';
export const COMPLETED_SELECTED = './images/completed-selected-icon.svg';

export const CHEGG_NAME = {
  NAME: 'Chegg',
  INTERNSHIP: 'Internships',
};

export const DEVELOPMENT = 'dev';

export const STATIC_NAV_STRINGS = {
  FIND_INTERNSHIPS: 'Find Internships',
  EMPLOYERS: 'EMPLOYERS',
  SIGN_IN: 'SIGN IN',
  CREATE_ACCOUNT: 'CREATE ACCOUNT',
};

export const METHOD_OPTIONS = {
  HEAD: 'head',
  OPTIONS: 'options',
  PUT: 'put',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
  GET: 'get',
};

export const NAV_OPTIONS = {
  MODULE: 'Module',
};

export const COURSE_STATUSES = {
  NOT_STARTED: 'notStarted',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
};

export const STATIC_DASHBOARD_STRINGS = {
  WELCOME: 'Welcome',
  SUB_TITLE_JUST_STARTED: 'What real-world skills will you build today?',
  SCROLL_THROUGH_LIBRARY: 'Take a scroll through the course library!',
  SUB_TITLE_PROGRAM_LIBRARY: 'Each program includes real-world examples a certificate of completion',
  WELCOME_BACK: 'Welcome back',
  DOING_GREAT: 'Your progress is awesome!',
  NOT_STARTED_SKILL: 'Let’s start with a new Skill',
  IN_PROGRESS_SKILL: 'Let’s finish learning your Skill',
  COMPLETED_SKILL: 'Your Skills',
  LEARNING_NOT_STARTED: 'Select a program below and start learning today!',
  WELCOME_TEXT_NEW_USER: 'Each program includes real-world examples a certificate of completion',
  GET_STARTED: 'Get Started',
  RECOMMENDED: 'Recommended Programs',
};

export const DASHBOARD_VIEW_TYPES = {
  MY_COURSES: 'My Programs',
  COMPLETED: 'Completed',
  COURSE_LIBRARY: 'Program Library',
};

export const DASH_TYPES = [
  { name: 'My Programs', icon: HOME_ICON, selectedIcon: HOME_CHOSEN_ICON },
  { name: 'Program Library', icon: LIBRARY_ICON, selectedIcon: LIBRARY_CHOSEN_ICON },
  { name: 'Completed', icon: COMPLETED_GREY, selectedIcon: COMPLETED_SELECTED },
];

export const STATIC_SKILLS_CARD_STRINGS = {
  TITLE: 'Learn Data Analytics with Excel',
  GET_STARTED: 'Get Started',
  START_LEARNING: 'Start Learning',
  RESUME_LEARNING: 'Resume Learning',
  CONTINUE_LEARNING: 'Continue learning',
  REVIEW_PROGRAM: 'Review Program',
  BEGIN_LEARNING: 'Begin Learning',
  QUIZ_SKILLS: [
    { TYPE: 'BEGINNER_FRIENDLY', TEXT: 'Beginner-friendly', ICON: BEGINNER_FRIENDLY },
    { TYPE: 'CERTIFICATE', TEXT: '1 skill certificate', ICON: CERTIFICATE_ICON },
  ],
  BESTSELLER: 'Bestseller',
  VIEWED_SKILL: '80% of students from the same field learned this skill',
  PERCENTAGE: '% completed!',
  MODULE: 'Module',
  COMPLETED: 'Completed!',
  PROGRESS: 'Progress',
  LAST_STUDIED: 'Last Studied',
  IN_PROGRESS: 'In Progress',
  NEXT_STEP: 'NEXT STEP',
  FULL_SYLLABUS: 'Show All',
  CLOSE_SYLLABUS: 'Hide',
  GET_CERTIFICATE: 'Get Certificate',
  PRACTICE: 'Practice',
};

export const QUIZ_SUBMISSION_OPTIONS = {
  CONTINUE: 'Continue',
  CHECK: 'Check',
  IS_SELECTED: 'isSelected',
  VIEWED_EXPLANATION: 'viewedExplanation',
};
export const TIME_OPTIONS = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
};
export const CHANGE_SCREEN_OPTION = {
  BUTTON: 'button',
  BACK_ARROW: 'back',
  FORWARD_ARROW: 'forward',
};

export const NEXT_BTN = 'Next';

export const CONTINUE_BTN = 'Continue';

export const BACK_BTN = 'Back';

export const FIRST_PAGE_IN_MODULE = '0';

export const ESTIMATION = 'Estimated completion time';

export const BACK_TO_DASHBOARD = 'Back to Dashboard';

export const STATIC_QUIZ_STRINGS = {
  CONTINUE_MODULE: 'Continue',
  SHOW_EXPLANATION: 'Show explanation',
  BACK_TO_QUESTION: 'Back to question',
  GOOD_JOB: 'Good job!',
  NOT_QUITE: 'Not quite',
  EXPLANATION: 'Explanation',
};

export const AUTHENTICATION_STATIC_STRINGS = {
  STUDENTS: 'SKILLS FOR STUDENTS',
  HIRING_MANAGERS: '84% of hiring managers look for students with job-ready skills',
};

export const ERROR_STRINGS = {
  TITLE: 'Oops, we\'re sorry!',
  SUB_TITLE: 'Like the abominable snowman, this page does not exist.',
};

export const FIRST_POSITION = 0;

export const COMPLETION_TYPES = {
  MODULE: 'module',
  SCREEN: 'screen',
};

export const QUIZ_TYPES = {
  SURVEY: 'survey',
  MULTIPLE_CHOICE: 'multiple-choice',
};

export const INDEXED_DB_TYPE = ['enrollments', 'courseStructure', 'userPosition', 'courses'];

export const CHEGG_ENVIRONMENT_TYPES = {
  prod: 'prod',
  test: 'test',
};

export const FE_ENDPOINTS = [
  'https://localhost:3000/',
  'https://localhost:3000/authentication',
  'https://skills.stage.internships.com/',
  'https://skills.stage.internships.com/authentication',
  'https://skills.internships.com/',
  'https://skills.internships.com/authentication',
];

export const NAVBAR_OPTIONS = [
  { link: 'Log out', icon: LOGOUT_ICON },
  { link: 'Report issue', icon: HELP_ICON },
];

export const NAV_LINK_NAMES = {
  LOGOUT: 'Log out',
  HELP: 'Report issue',
};

export const UNLOCK_CERTIFICATE = 'Unlock your certificate';

export const DOWNLOAD_YOUR_CERTIFICATE = 'Download your certificate';

export const COMPLETED_COURSE = 100;

export const TEMP_UPDATE_USER_POSITION_DATA = {
  URLS: [
    'https://localhost:3000/player/1/module-2/22',
    'https://skills.stage.internships.com/player/1/module-2/22',
    'https://skills.internships.com/player/1/module-2/22',
  ],
  MOD_TWO: 'module-2',
  MOD_THREE: 'module-3',
  NO_TYPE: '',
};

export const PAGE_VIEW_NAMES = {
  DASHBOARD: 'dashboard',
  HOME_PAGE: 'home page',
  SYLLABUS: 'syllabus',
  PLAYER: 'player',
};

export const COURSE_COMPLETED = true;

export const NO_PROGRAM_LIBRARY = {
  BACK_TO_MY_PROGRAMS: 'Back to My Programs',
  LEARN_MORE: 'Looking to learn more?',
  ADDING_MORE: 'We’re working on adding new programs each and every week. You’ll get a notification as soon as we do.',
};

export const CERTIFICATE_TYPES = {
  EXCEL: 'Excel for Data & Analytics',
  EMAIL_MARKETING: 'Email Marketing',
  SEO_SEM: 'SEO/SEM',
  SOCIAL_MEDIA_MARKETING: 'Social Media Marketing',
};

export const SUBSCRIPTION_TYPES = [
  'CS_SUB',
  'CS_SUB_V2',
  'CS_SUB_6_MONTH',
  'CS_SUB_12_MONTH',
];

export const MODULES_COMPLETE = 'modules complete';

export const AUTH_FLOW_QUESTIONS = [
  {
    type: 'onboarding',
    question: 'How do you plan on using Chegg Skills for Students?',
    answers: [
      'Skill Development: Improving existing skills and learning new topics.',
      'Career Exploration: Better understanding potential career paths that I might pursue in the future.',
      'Internship or Job Preparation: Acquiring skills for specific internships or job opportunities that I\'m interested in.',
      'Other'],
    image: QUESTION_1,
  },
  {
    type: 'onboarding',
    question: 'Set a weekly learning goal',
    answers: ['In between classes (30 min)', 'During dedicated study breaks (1 hour)', 'Treat this like a class (1.5 hours) ', 'Weekend warrior (2 hours)', 'Add a new skill to my resume ASAP (3+ hours)'],
    image: QUESTION_2,
  },
];

export const FINAL_AUTH_FLOW_SCREEN = {
  title: 'Welcome to Chegg Skills for Students!',
  subTitle: 'Our programs are all 10 hours or less each featuring: ',
  points: [
    'Designed exclusively for college students',
    'Real world examples across multiple career paths',
    'Bite-sized content for easy reading',
    'Resume boosting certificate of completion',
    'Career tips to get you internship ready',
  ],
  mainImage: MAIN_FINAL_SCREEN_PIC,
};

export const AUTH_BUTTONS = {
  CONTINUE: 'Continue',
  SKIP: 'Skip',
};

export const STEP = 'Step ';

export const INSIDE_PLAYER = '/player';

export const INSIDE_DASHBOARD = '/dashboard';

export const UNSUBSCRIBED_BANNER_TEXT = 'Unlock the entire Chegg Skills library\n featuring over 20+ programs';

export const CARD_TYPES = {
  ENROLLMENT: 'enrollment',
  COURSE: 'course',
};

export const ANSWER_OUTCOME = {
  CORRECT: 'Good job! You’re correct.',
  INCORRECT: 'Not quite! The correct answer is',
};

export const EMPTY_COMPLETED_TAB_STRINGS = {
  NO_COMPLETED: 'No completed courses',
  NO_COMPLETED_SUB_TITLE: 'Each program includes real-world examples a certificate of completion',
  GET_STARTED: 'Get started',
};

export const COMPLETED_PROGRAM_STRINGS = {
  GREAT_JOB: 'Great Job!',
  PROGRAM: 'program',
  GREAT_JOB_SUB_TITLE: 'You’ve completed the ',
  GET_CERT: 'Get Certificate',
  WHATS_NEXT: 'What’s next?',
  WHATS_NEXT_STEPS: ['Download certificate', 'Add new skill to your resume', 'Share your progress', 'Start a new program!'],
};

export const PROGRAM_LIBRARY_STRINGS = {
  TITLE: 'Select a program below and start learning today!',
  SUB_TITLE: 'Each program includes real-world examples a certificate of completion',
};

export const STATUS_DROP_OPTIONS = {
  DEFAULT: 'Status',
  OPTIONS: ['Not started', 'In progress', 'Completed'],
};

export const CAREER_PATH = 'Career Path';

export const DROP_BUTTONS = {
  DONE: 'Done',
  CLEAR: 'Clear',
};

export const STATUS_OPTIONS = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
};

export const FILTERS = 'Filters';

export const FILTER_EMPTY_SPACE = {
  FILTER_TITLE: 'No Results Found',
  FILTER_SUB_TITLE: 'Your search did not match any items. Try adjusting your filters or explore other options',
  CLEAR_FILTERS: 'Clear filters',
};

export const OTHER = 'Other';

export const INITIAL_MODULE_CONTENT = {
  moduleIndex: 0,
  screens: [],
  id: '',
  displayName: '',
};

export const INITIAL_ENROLLMENT = {
  current_screen: '0',
  current_module: 'module-1',
  completed: false,
  id: 0,
};

export const INITIAL_SCREEN_CONTENT = {
  id: '',
};
