import React from 'react';
import { AuthQuestionnaire } from '../../components';
import logo from '../../assets/images/chegg-logo.svg';

const AuthenticationFlow = () => (
  <div className='h-screen w-screen flex justify-center items-center relative'>
    <div className='w-[93vw] h-[95vh] flex items-center'>
      <div className='h-[4.5rem] absolute top-0 left-[3rem] w-[45%] max-xl:w-[95%] bg-white flex items-center'>
        <img src={logo} alt='logo' />
      </div>

      <AuthQuestionnaire />
    </div>
  </div>
);

export default AuthenticationFlow;
