import React from 'react';
import { COMPLETED_PROGRAM_STRINGS } from '../../constants';

const WhatsNext = () => {
  const { WHATS_NEXT, WHATS_NEXT_STEPS } = COMPLETED_PROGRAM_STRINGS;
  return (
    <div className='w-[32.313rem] h-[23.313rem] sm:shadow-lastStudiedBox mt-[2.625rem] rounded-[0.5rem] flex flex-col items-center mb-[2rem]'>
      <div className='text-[#EC7201] text-[1.25rem] font-aspira800 mt-[2rem] mb-[1.5rem]'>{WHATS_NEXT}</div>
      <div className='text-start'>
        {WHATS_NEXT_STEPS.map((option, index) => (
          <div className='flex flex-col' key={option}>
            <div className='flex items-center'>
              <div className='h-[1.75rem] w-[1.75rem] rounded-full border border-1 border-[#747283] flex justify-center items-center text-[1.25rem] text-[#747283] font-aspira400'>{index + 1}</div>
              <div className='ml-[0.75rem] text-[0.875rem] text-[#747283] font-aspira700'>{option}</div>
            </div>
            {index + 1 !== WHATS_NEXT_STEPS.length && (
              <div className='w-[1.75rem] flex justify-center'>
                <div className='my-[0.5rem] bg-[#CAC8D1] rounded-[3.125rem] h-[1.688rem] w-[0.125rem]' />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatsNext;
