import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document } from '@contentful/rich-text-types';
import { STATIC_QUIZ_STRINGS } from '../../constants';
import { PlayerContent } from '../index';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';

interface IProps {
  title?: string;
  explanation: Document;
  handleFlipCard: () => void;
  isExplanationViewed: boolean;
}

const QuizExplanation = ({
  explanation, handleFlipCard, title, isExplanationViewed,
}: IProps) => {
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);
  const [hasInitialScroll, setHasInitialScroll] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setIsScrollAtBottom(isAtBottom);
    }
  };

  // Function to check initial scroll position
  const checkInitialScroll = () => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      const isAtBottom = divElement.scrollTop + divElement.clientHeight + 0.5 === divElement.scrollHeight || divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight;
      setHasInitialScroll(!isAtBottom);
    }
  };

  // Add scroll event listener and check initial scroll when the component mounts
  useEffect(() => {
    const divElement = document.getElementById('quiz'); // Replace with your actual div's ID
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      checkInitialScroll();
    }

    // Remove the scroll event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const { BACK_TO_QUESTION, EXPLANATION } = STATIC_QUIZ_STRINGS;

  return (
    <div className={`w-full h-full relative ${!isExplanationViewed ? 'hidden' : ''} max-lg:flex max-lg:justify-center max-lg:w-screen`}>
      <div id='quiz' className='bg-white lg:shadow-lastStudiedBox max-lg:mt-[1rem] rounded-[0.5rem] h-full lg:w-[40vw] sm:w-[90vw] max-sm:w-[100vw] p-[3.5rem] max-sm:p-[1rem] lg:overflow-y-auto picker-scroll'>
        <div className='max-lg:hidden fixed top-0 left-1 w-[97%] h-[3.75rem] max-sm:h-[2.25rem] bg-white z-50'>
          <div className='absolute bottom-[0.25rem] w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem] ml-[3rem] max-sm:ml-[1rem]' />
        </div>

        <div className='lg:hidden w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem]' />

        {title ? <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{title}</div> : <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{EXPLANATION}</div>}
        <div className='text-[1rem] text-[#474358] font-aspira400 max-lg:mb-[3.75rem]'>
          <PlayerContent content={explanation} />
        </div>

        {hasInitialScroll && !isScrollAtBottom
          ? <div className={`fixed z-[70] left-0 h-[4.5rem] w-full bg-gradient-to-b from-transparent to-white ${title ? 'rounded-b-[0.5rem] bottom-0' : 'bottom-[5.125rem]'}`} />
          : null}

        {!title && (
          <div className='lg:fixed lg:bottom-0 cursor-pointer bg-white lg:h-[5.125rem] lg:w-[85%]'>
            <div
              className='flex items-center cursor-pointer'
              onClick={async () => {
                await useClickstreamInteraction('click', 'button', 'back', 'Back to question');
                handleFlipCard();
              }}
              aria-hidden
            >
              <div className='text-[#4727C3] text-[1.25rem] font-aspira700 underline underline-offset-4'>{BACK_TO_QUESTION}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizExplanation;
