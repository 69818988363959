import React from 'react';

const Loader = () => (
  <div className='flex loader'>
    <div className='w-3 h-3 m-0.5 rounded-full bg-orange' />
    <div className='w-3 h-3 m-0.5 rounded-full bg-orange' />
    <div className='w-3 h-3 m-0.5 rounded-full bg-orange' />
  </div>
);

export default Loader;
