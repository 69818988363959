import {
  ICourse, IEnrollment, IMyCourseStructure, IProgress,
} from '../interfaces';
import { CARD_TYPES, COMPLETED_COURSE, STATUS_OPTIONS } from '../constants';

const { ENROLLMENT, COURSE } = CARD_TYPES;

const { COMPLETED, NOT_STARTED, IN_PROGRESS } = STATUS_OPTIONS;

export const percentages = (values: number[], totalValue: number) => values.map((length: number) => (((length / totalValue) * COMPLETED_COURSE) <= COMPLETED_COURSE ? ((length / totalValue) * COMPLETED_COURSE) : COMPLETED_COURSE));
export const percentage = (values: number, totalValue: number) => (((values / totalValue) * COMPLETED_COURSE) <= COMPLETED_COURSE ? ((values / totalValue) * COMPLETED_COURSE) : COMPLETED_COURSE);

export const sortByDate = (a:IEnrollment | ICourse, b:IEnrollment | ICourse) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();

export const lastStringCharToNumber = (stg: string) => +stg![stg!.length - 1];

export const decodeIdToken = (idToken: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, token] = idToken.slice(idToken.indexOf('=') + 1).split('.');
  return JSON.parse(atob(token));
};

export const calculateTime = (timeEstimation: number) => {
  const hours = (timeEstimation / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const newHours = minutes >= 30 ? rhours + +1 : rhours;
  return newHours > 1 ? `${newHours} hours` : '1 hour';
};

export const coursePercentage = (progress: IProgress[]) => {
  let totalScreens = 0;
  let totalCompletedScreens = 0;
  progress.forEach((moduleProgress, index) => {
    totalScreens += moduleProgress[`module-${index + 1}`].total_screens;
    totalCompletedScreens += moduleProgress[`module-${index + 1}`].completed_screens;
  });

  return ((totalCompletedScreens / totalScreens) * 100);
};

export const mergeObjects = (obj1: any, obj2: any) => {
  Object.keys(obj1).forEach(key => {
    if (obj2[key]) obj1[key] = [...obj1[key], ...obj2[key]];
  });

  Object.keys(obj2).forEach(key => {
    if (!obj1[key]) obj1[key] = obj2[key];
  });

  return obj1;
};

export const courseStructureView = (courses: ICourse[], enrollments: IEnrollment[], statuses: string[], tracks: string[]) => {
  const structuredCourses: any = {};
  const structuredEnrolledCourses: any = {};

  let filteredCourses: ICourse[] = courses;

  if (tracks.length) filteredCourses = courses.filter(course => tracks.includes(course.career_track));

  filteredCourses.forEach(course => {
    const enrolledCourse = enrollments.filter(enrollment => enrollment.course.id === course.id);
    if (enrolledCourse.length) {
      if (!statuses.length || (statuses.length && ((enrolledCourse[0].completed && statuses.includes(COMPLETED)) || (!enrolledCourse[0].completed && statuses.includes(IN_PROGRESS))))) {
        if (structuredEnrolledCourses[course.career_track]) structuredEnrolledCourses[course.career_track].push({ ...enrolledCourse[0], cardType: ENROLLMENT });
        else structuredEnrolledCourses[course.career_track] = [{ ...enrolledCourse[0], cardType: ENROLLMENT }];
      }
    } else if (!statuses.length || (statuses.length && statuses.includes(NOT_STARTED))) {
      if (structuredCourses[course.career_track]) structuredCourses[course.career_track].push({ ...course, cardType: COURSE });
      else structuredCourses[course.career_track] = [{ ...course, cardType: COURSE }];
    }
  });

  Object.keys(structuredCourses).forEach(key => structuredCourses[key].sort((a: ICourse, b: ICourse) => a.name.localeCompare(b.name)));

  if (Object.keys(structuredEnrolledCourses)) {
    Object.keys(structuredEnrolledCourses).forEach(key => {
      structuredEnrolledCourses[key].sort((a: IEnrollment, b: IEnrollment) => coursePercentage(a.progress) - coursePercentage(b.progress));
    });
  }

  return mergeObjects(structuredCourses, structuredEnrolledCourses);
};

export const structureMyProgramPage = (courses: ICourse[], enrollments: IEnrollment[]) => {
  const structuredPrograms: IMyCourseStructure = {
    In_Progress: [],
    Recommended_Programs: [],
  };
  enrollments.forEach(enrollment => {
    if (!enrollment.completed) structuredPrograms.In_Progress!.push({ ...enrollment, cardType: ENROLLMENT });
  });
  if (structuredPrograms.In_Progress!.length) {
    structuredPrograms.In_Progress!.sort((a: IEnrollment, b: IEnrollment) => coursePercentage(b.progress) - coursePercentage(a.progress));
    const lastUpdated = structuredPrograms.In_Progress!.sort((a, b) => sortByDate(a, b))[0];
    const latItemIndex = structuredPrograms.In_Progress!.findIndex(item => item.id === lastUpdated.id);
    structuredPrograms.In_Progress!.splice(latItemIndex, 1);
  }

  courses.forEach(course => {
    if (course.isRecommended) structuredPrograms.Recommended_Programs.push({ ...course, cardType: COURSE });
  });

  if (structuredPrograms.Recommended_Programs.length) {
    structuredPrograms.Recommended_Programs = structuredPrograms.Recommended_Programs
      .sort((a: ICourse, b: ICourse) => sortByDate(a, b))
      .filter(course => !enrollments.some(enrollment => enrollment.course.id === course.id));
  }
  return structuredPrograms;
};
