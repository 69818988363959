import React from 'react';
import { MODULES_COMPLETE } from '../../constants';

interface IProps {
    numerator: number
    denominator: number
}

const CardProgramProgress = ({ numerator, denominator }: IProps) => (
  <div className='w-[8.5rem] max-xl:w-full'>
    <div className='w-full flex items-center justify-center'>
      <div className='font-aspira700 text-[1.375rem] mr-[0.2rem]'>{numerator}</div>
      <div className='font-aspira400'>/</div>
      <div className='font-aspira400 ml-[0.2rem]'>{denominator}</div>
    </div>

    <div className='font-aspira400 mb-[0.5rem] max-xl:flex max-xl:justify-center'>{MODULES_COMPLETE}</div>

    <div className='w-full h-[0.5rem] overflow-hidden rounded-[2rem] flex items-center'>
      <progress className='w-full' value={numerator && denominator ? (numerator / denominator) * 100 : ''} max={100} />
    </div>
  </div>
);

export default CardProgramProgress;
