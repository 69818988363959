import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Environment, init } from '@chegg/one-auth-sdk';
import * as braze from '@braze/web-sdk';
import { getRioClient, getRioConfig } from './rio/indes';
import { CHEGG_ENVIRONMENT_TYPES } from './constants';

const { test, prod } = CHEGG_ENVIRONMENT_TYPES;
const env = process.env.REACT_APP_CHEGG_ENV === prod ? prod : test;
init({
  env: Environment[env as keyof typeof CHEGG_ENVIRONMENT_TYPES],
  clientId: 'CJP',
  domainUrl: '.internships.com',
});

const App = () => {
  // initialize the braze SDK
  braze.initialize(process.env.REACT_APP_BRAZE_API_KEY as string, {
    baseUrl: process.env.REACT_APP_BRAZE_ENDPOINT as string,
    enableLogging: true,
  });

  braze.requestPushPermission();

  useEffect(() => {
    async function initiateRio() {
      const rioClient = await getRioClient();
      rioClient.init(getRioConfig());
    }

    initiateRio();
  }, []);

  return (
    <Outlet />
  );
};

export default App;
