import React from 'react';
import {
  OneAuth, OneAuthCallback, AuthPayload, LogPayload,
} from '@chegg/one-auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AUTHENTICATIONFLOW, DASHBOARD } from '../../pages/route_links';
import { getRioConfig, updateRioState } from '../../rio/indes';
import { decodeIdToken } from '../../utils';
import { checkSubscribed } from '../../services/subscription.service';
import { BACKEND_ROUTES } from '../../services/backendRoutes';
import { DEVELOPMENT, INSIDE_DASHBOARD, INSIDE_PLAYER } from '../../constants';
import { subscriptionStore } from '../../store/Subscription';

let newUser = false;

const AuthenticationComponent = () => {
  const navigate = useNavigate();
  const [setSubscription] = subscriptionStore((state) => [state.setSubscription]);

  const callback: OneAuthCallback = async (params) => {
    const { type, payload } = params;
    const { status, data } = payload as AuthPayload;
    if (data.sign_up) newUser = true;
    switch (type) {
      case 'log':
        // eslint-disable-next-line no-case-declarations
        const { data: loggedData, type: loggedType } = payload as LogPayload;
        console.log('data', loggedData);
        console.log('type', loggedType);
        break;
      case 'auth':
        switch (status) {
          case 'success':
            if (data && data.idToken) {
              if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) localStorage.setItem('token', data.idToken);
              const jwtData = await decodeIdToken(data.idToken);
              await updateRioState({
                authState: 'hard_or_soft_logged_in',
                cheggUuid: jwtData.sub,
              });
              if (data.accessToken && data.idToken) {
                const isSubscribed = await checkSubscribed(data.accessToken, data.idToken);
                setSubscription(isSubscribed);
                await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/${BACKEND_ROUTES.SET_SUBSCRIPTION}`, { isSubscribed }, { headers: { Authorization: `Bearer ${data.accessToken}`, Chegg_token: `Bearer ${data.idToken}` } });
              }
              const currentUrl = window.location.href;
              if (newUser && !currentUrl.includes(INSIDE_PLAYER) && !currentUrl.includes(INSIDE_DASHBOARD)) {
                navigate(AUTHENTICATIONFLOW);
                newUser = false;
              } else if (!currentUrl.includes(INSIDE_PLAYER) && !currentUrl.includes(INSIDE_DASHBOARD) && !currentUrl.includes(AUTHENTICATIONFLOW)) navigate(DASHBOARD);
            }
            break;
          case 'error':
            console.log('Unsuccessful login or signup');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className='auth-override'>
      <OneAuth
        callback={callback}
        rioConfig={getRioConfig()}
        env={process.env.REACT_APP_CHEGG_ENV as any}
      />
    </div>
  );
};

export default AuthenticationComponent;
