import React from 'react';
import { Line } from 'rc-progress';

interface IProps {
  conStyle: string;
  percents: number;
  lineStyle?: string;
}

const PercentageProgressBar = ({ lineStyle, conStyle, percents }: IProps) => {
  const strokeColor = percents > 1 ? '#EB7100' : '#E2E1E6';
  return (
    <div className={`${conStyle} flex justify-center`}>
      <Line className={lineStyle} percent={percents} strokeWidth={4} trailWidth={4} trailColor='#E2E1E6' strokeColor={strokeColor} />
    </div>
  );
};

export default PercentageProgressBar;
