// eslint-disable-next-line import/no-unresolved
import { ViewExperience } from '@chegg/rio-sdk-web/dist/types/baseEventWeb';
import { sendRioEvent, updateRioState } from '../indes';
import { NotificationsBase } from '../../eventSchemas';

export const useClickstreamNotification = async (type: NotificationsBase['type'], message_name: string, message_detail: string, content_value?: string, view_name?: string) => {
  if (content_value) {
    await updateRioState({
      view: {
        view_name,
        experience: 'sfs' as ViewExperience,
        property: 'skills',
        content: {
          content_value,
        },
      },
    });
  }
  sendRioEvent('clickstream_notifications', {
    notifications: {
      type,
      message_name,
      message_detail,
    },
  });
};
