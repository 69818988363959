import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document } from '@contentful/rich-text-types';
import {
  PlayerTitle, EstimatedTime, PlayerContent,
} from '../index';
import arrow from '../../assets/images/skinny-arrow-scroll.svg';

interface IProps {
  title?: string;
  timeEstimation?: number;
  markdown?: Document;
}

const LeftPlayer = ({ markdown, timeEstimation, title }: IProps) => {
  const [isScroll, setIsScroll] = useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [img, setImg] = useState<any>();
  const [con, setCon] = useState<any>();

  const div = document.getElementById('left-player');

  useEffect(() => {
    if (markdown) {
      const image = markdown.content.filter((item) => item.nodeType === 'embedded-asset-block')[0];
      const cons = markdown.content.filter((item) => item.nodeType === 'paragraph');
      if (image) setImg(image);
      if (cons.length) setCon(cons);
      if (div) div.scrollTop = 0;
      if (div && div.scrollHeight > div.offsetHeight) setIsScroll(true);
    }
  }, [markdown]);

  useEffect(() => {
    if (img) {
      if (div!.scrollHeight > div!.offsetHeight) setIsScroll(true);
    }
  }, [img]);

  useEffect(() => {
    if (con) {
      if (div!.scrollHeight > div!.offsetHeight) setIsScroll(true);
    }
  }, [con]);

  if (div) {
    div.addEventListener('scroll', () => {
      if (div.scrollTop < prevScrollHeight) setIsScroll(true);
      else if (div.scrollTop + div.clientHeight - 0.5 === div.scrollHeight || div.scrollTop + div.clientHeight === div.scrollHeight) setIsScroll(false);

      setPrevScrollHeight(div.scrollTop);
    });
  }

  const observer = new MutationObserver(() => {
    if (div && div.scrollHeight > div.offsetHeight) setIsScroll(true);
    else if (div && ((div.scrollTop + div.clientHeight - 0.5 === div.scrollHeight) || (div.scrollTop + div.clientHeight === div.scrollHeight))) setIsScroll(false);
    else setIsScroll(false);
  });

  const config = { childList: true, subtree: true };

  if (div) {
    observer.observe(div, config);
  }

  const scrollBottom = () => {
    div!.scrollTop = div!.scrollHeight;
    setIsScroll(false);
  };

  return (
    <div className='max-lg:w-screen max-lg:flex max-lg:flex-col max-lg:items-center'>
      <div className='flex flex-col lg:w-full sm:w-[80vw] max-sm:w-[100vw] mt-[3.75rem] max-lg:mt-[1.688rem] lg:pl-[3.75rem] lg:pr-[1.5rem] lg:mb-[10rem] max-[1.313rem] max-lg:px-[1rem]'>
        {title && (<PlayerTitle title={title} />)}

        {timeEstimation && (<EstimatedTime timeEstimation={timeEstimation} />)}

        {markdown && (<div className='lg:mt-[3rem] max-lg:mt-[1rem] text-[1.125rem]'><PlayerContent content={markdown} /></div>)}

      </div>
      <div className='max-lg:w-full max-lg:h-[0.5rem] max-lg:bg-[#E2E1E6] mt-[0.5rem] lg:hidden' />
      <div className='max-lg:hidden'>
        {
        isScroll && !((div && div.scrollTop + div.clientHeight === div.scrollHeight) || (div && div.scrollTop + div.clientHeight - 0.5 === div.scrollHeight)) ? (
          <div className='fixed z-[70] bottom-[6.5rem] left-0 h-[4.5rem] w-[50vw] flex justify-center items-center'>
            <div
              className='cursor-pointer relative z-[75] h-[3.5rem] w-[3.5rem] rounded-full bg-[#EEEEF1] shadow-scrollPopup flex justify-center items-center'
              onClick={() => scrollBottom()}
              aria-hidden
            >
              <img src={arrow} alt='arrow' className='rotate-90' />
            </div>
            <div className='fixed z-[70] bottom-[6.5rem] left-0 h-[4.5rem] w-[50vw] bg-gradient-to-b from-transparent to-white' />
          </div>
        ) : null
      }
      </div>
    </div>
  );
};

export default LeftPlayer;
