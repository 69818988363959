import { CLOCK_ICON as clockIcon } from '../../constants';

interface IProps {
  className: string;
  context: String;
}

const Clock = ({ className, context }: IProps) => (
  <div className='h-full flex items-center'>
    <img className='h-[1.25rem] mr-[0.5rem]' src={clockIcon} alt='clockIcon' />
    <div className={`${className} h-full flex items-center font-aspira400 text-[0.875rem]`}>
      {context}
    </div>
  </div>
);

export default Clock;
