import React from 'react';

interface IProps {
    selectedAnswer: string;
    answer: string;
    handleSelection: () => void;
}

const AuthFlowQuestion = ({ selectedAnswer, answer, handleSelection }: IProps) => (
  <div key={answer} className='flex items-center mb-[2rem] cursor-pointer' aria-hidden onClick={() => handleSelection()}>
    <div className={`flex justify-center items-center min-w-[1.5rem] h-[1.5rem] mr-[0.75rem] border border-1 border-[#534F63] ${selectedAnswer === answer && 'border-orange'} rounded-full`}>
      <div className={selectedAnswer === answer ? '' : 'hidden'}>
        <div className='w-[0.875rem] h-[0.875rem] rounded-full bg-orange' />
      </div>
    </div>
    <div className='text-[#302C41] font-aspira400 mt-[0.25rem]'>{answer}</div>
  </div>
);

export default AuthFlowQuestion;
