import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Error, LeftPlayer, Loader, PlayerTopBar, RightPlayer, CompletedProgramPopup,
} from '../../components';
import {
  CHANGE_SCREEN_OPTION, FIRST_PAGE_IN_MODULE, METHOD_OPTIONS, FIRST_POSITION, COMPLETION_TYPES,
  PAGE_VIEW_NAMES, COURSE_COMPLETED, INITIAL_MODULE_CONTENT, INITIAL_ENROLLMENT, INITIAL_SCREEN_CONTENT,
} from '../../constants';
import { DASHBOARD, PLAYER } from '../route_links';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import { BACKEND_ROUTES } from '../../services/backendRoutes';
import { courseStructureStore } from '../../store/CourseStructure';
import { courseEnrollmentsStore } from '../../store/CourseEnrollments';
import { ICompletion, IModule, IScreen } from '../../interfaces';
import { useClickstreamView } from '../../rio/rioHooks/useClickstreamView';
import { useClickstreamModuleNotifications } from '../../rio/rioHooks/useClickstreamModuleNotifications';
import { useClickstreamNotification } from '../../rio/rioHooks/useClickstreamNotifications';

const Player = () => {
  const navigate = useNavigate();
  const {
    courseId, versionId, moduleId, screenNum,
  } = useParams();

  const { GET, POST } = METHOD_OPTIONS;
  const { FORWARD_ARROW, BACK_ARROW } = CHANGE_SCREEN_OPTION;
  const { GET_MODULE, CREATE_SCREEN_COMPLETION } = BACKEND_ROUTES;
  const { MODULE, SCREEN } = COMPLETION_TYPES;

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);

  const [moduleContent, setModuleContent] = useState<IModule>();
  const [screenContent, setScreenContent] = useState<IScreen>();
  const [response, error, loading, axiosFetch] = useAxiosFunction();
  const [isProgramCompleted, setIsProgramCompleted] = useState(false);

  const structure = courseStructureStore((state) => state.structure);
  const [enrollments, setCourseEnrollments] = courseEnrollmentsStore((state) => [state.enrollments, state.setCourseEnrollments]);
  const enrollment = courseEnrollmentsStore((state) => state.enrollments.find(({ course }) => course.id === +courseId!));

  const {
    moduleIndex, screens, id: modId, displayName,
  } = moduleContent ?? INITIAL_MODULE_CONTENT;

  const {
    current_screen, current_module, completed, id: enrollmentId,
  } = enrollment ?? INITIAL_ENROLLMENT;

  const { id: screenId } = screenContent ?? INITIAL_SCREEN_CONTENT;

  useClickstreamView(PAGE_VIEW_NAMES.PLAYER, screenNum);
  useClickstreamModuleNotifications(courseId!, +moduleId!.slice(-1) !== structure.length ? moduleId! : '');

  const getModule = (mod: string) => axiosFetch({
    method: GET,
    url: GET_MODULE,
    data: { courseVersionId: versionId, moduleId: mod },
  });

  const createScreenCompletion = (completions: ICompletion[]) => axiosFetch({
    method: POST,
    url: CREATE_SCREEN_COMPLETION,
    data: { completions },
  });

  useEffect(() => {
    async function initialSetup() {
      await getModule(moduleId!);
    }

    initialSetup();
  }, []);

  useEffect(() => {
    async function setCompletion() {
      const { url, data } = response;
      if (url === GET_MODULE) {
        setModuleContent(data);
        if (data.moduleIndex < +current_module.split('-')[1] - 1 || (data.moduleIndex === +current_module.split('-')[1] - 1 && screenNum! === FIRST_PAGE_IN_MODULE)) {
          if (screenNum! === FIRST_PAGE_IN_MODULE) setScreenContent(data.screens[0]);
          else setScreenContent(data.screens[data.screens.length - 1]);
        } else if (data.moduleIndex === structure.length - 1 && structure[structure.length - 1].screensLength! === +current_screen) setScreenContent(data.screens[data.screens.length - 1]);
        else setScreenContent(data.screens[+current_screen]);
      } else if (url === CREATE_SCREEN_COMPLETION) {
        setCourseEnrollments(enrollments.map(storedEnrollment => (storedEnrollment.id === data.id ? { ...data, course: storedEnrollment.course } : storedEnrollment)));
        setScreenContent(screens[+data.current_screen]);
        if (data.current_screen === FIRST_PAGE_IN_MODULE) await getModule(data.current_module);
        navigate(generatePath(PLAYER, {
          courseId: courseId!, versionId: versionId!, moduleId: data.current_module, screenNum: data.current_screen,
        }));
      }
    }

    setCompletion();
  }, [response]);

  // When the user moves to a specific module based off arrow or module click.
  const handleChangeModule = async (moduleToView: number) => {
    if (moduleToView < +current_module.split('-')[1]) {
      await getModule(`module-${moduleToView}`);
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: `module-${moduleToView}`, screenNum: (structure[moduleToView - 1].screensLength! - 1).toString(),
      }));
    }
    if (moduleToView === +current_module.split('-')[1]) {
      await getModule(`module-${moduleToView}`);
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: current_module, screenNum: moduleToView === structure.length && structure[structure.length - 1].screensLength! === +current_screen ? (+current_screen - 1).toString() : current_screen,
      }));
    }
  };

  // When the user completes a screen for the first time.
  const handleCompletedScreen = async () => {
    await createScreenCompletion([{ course_enrollment_id: enrollmentId, completed_id: screenId, completed_type: SCREEN }]);
  };

  // When the user completes a module for the first time.
  const handleCompletedModule = async () => {
    await createScreenCompletion([
      { course_enrollment_id: enrollmentId, completed_id: screenId, completed_type: SCREEN },
      {
        course_enrollment_id: enrollmentId,
        completed_id: structure[moduleIndex].id,
        completed_type: MODULE,
        module_to_update:
        structure[moduleIndex + 1].id,
        screen_to_update: FIRST_PAGE_IN_MODULE,
        course_name: enrollment!.course.name,
        module_name: displayName,
      },
    ]);
    useClickstreamNotification('info', 'module completed', `${courseId!}/${current_module}`, `${screens.length.toString()}`, PAGE_VIEW_NAMES.PLAYER);
  };

  // When the user completes a program for the first time.
  const handleCompletedProgram = async () => {
    await createScreenCompletion([
      { course_enrollment_id: enrollmentId, completed_id: screenId, completed_type: SCREEN },
      {
        course_enrollment_id: enrollmentId,
        completed_id: current_module,
        completed_type: MODULE,
        module_to_update: current_module,
        screen_to_update: screens.length.toString(),
        course_name: enrollment!.course.name,
        module_name: displayName,
        completed: COURSE_COMPLETED,
      },
    ]);
    useClickstreamNotification('info', 'module completed', `${courseId!}/${current_module}`, `${screens.length.toString()}`, PAGE_VIEW_NAMES.PLAYER);
    useClickstreamNotification('info', 'program completed', `${courseId!}`);
    setIsProgramCompleted(true);
  };

  // When the user reviews completed screens, modules and program.
  const handleNonCompletedProgramContinue = async () => {
    if (!completed && +current_screen === screens.length - 1 && current_module === structure[structure.length - 1].id) {
      await handleCompletedProgram();
    } else if (screens.length - 1 === +current_screen && current_module === modId) {
      await handleCompletedModule();
    } else await handleCompletedScreen();
  };

  // when the user moves forward to a screen they have already completed
  const handleCompletedContinue = async () => {
    if (current_module === structure[structure.length - 1].id && +current_screen === screens.length && completed) navigate(DASHBOARD);
    else if (screens.length - 1 === +screenNum! && moduleId! === modId) {
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: `module-${+moduleId.split('-')[1] + 1}`, screenNum: '0',
      }));
      await getModule(`module-${+moduleId.split('-')[1] + 1}`);
    } else {
      setScreenContent(screens[+screenNum! + 1]);
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: moduleId!, screenNum: (+screenNum! + 1).toString(),
      }));
    }
  };

  // When the user presses the back button on the player
  const handleBackToPreviousPage = async () => {
    if (screenNum! !== FIRST_PAGE_IN_MODULE) {
      setScreenContent(screens[+screenNum! - 1]);
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: moduleId!, screenNum: (+screenNum! - 1).toString(),
      }));
    } else {
      await getModule(structure[moduleIndex - 1].id);
      navigate(generatePath(PLAYER, {
        courseId: courseId!, versionId: versionId!, moduleId: structure[moduleIndex - 1].id, screenNum: (structure[moduleIndex - 1].screensLength! - 1).toString(),
      }));
    }
  };

  const handleScreenChange = async (mode: string) => {
    switch (mode) {
      case FORWARD_ARROW:
        if (completed || +moduleId!.split('-')[1] < +current_module.split('-')[1] || (+moduleId!.split('-')[1] === +current_module.split('-')[1] && +screenNum! < +current_screen)) await handleCompletedContinue();
        else await handleNonCompletedProgramContinue();
        break;
      case BACK_ARROW:
        await handleBackToPreviousPage();
        break;
      default:
        break;
    }
    window.scrollTo(0, 0);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      setIsScrollingUp(false);
    }

    if (touchStart - touchEnd < -50) {
      setIsScrollingUp(true);
    }
  };

  return (
    <div
      className={`flex flex-col h-screen relative ${isProgramCompleted ? 'overflow-hidden' : ''}`}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchEnd={() => handleTouchEnd()}
    >
      {loading && (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Loader />
      </div>
      )}

      {error && (<Error />)}

      <PlayerTopBar
        denominator={moduleContent && moduleContent.screens ? moduleContent.screens.length : 0}
        handleChangeModule={handleChangeModule}
        isLeftOption={moduleContent && moduleContent.moduleIndex === FIRST_POSITION}
        isRightOption={moduleContent && +current_module.split('-')[1] - 1 <= moduleContent.moduleIndex && +current_screen <= +screenNum!}
        scrollBehavior={isScrollingUp}
      />

      {!error && !loading && (
      <div>
        <div className='flex max-lg:flex-col h-screen w-full'>
          <div id='left-player' className='pt-[5.688rem]  lg:w-1/2 max-lg:w-full lg:overflow-y-auto lg:border-r lg:border-1-r lg:border-r-[#E2E1E6] scrollbar'>
            <LeftPlayer
              title={screenContent && screenContent.title ? screenContent.title : undefined}
              timeEstimation={screenContent && screenContent.timeEstimation ? screenContent.timeEstimation : undefined}
              markdown={screenContent && screenContent.markdown ? screenContent.markdown : undefined}
            />
          </div>

          <div className='lg:w-1/2 max-lg:w-full max-lg:h-full lg:border-l lg:border-1-l lg:border-l-[#E2E1E6] lg:bg-[#FBFAFB]'>
            <RightPlayer
              image={screenContent && screenContent.image ? screenContent.image : undefined}
              quiz={screenContent && screenContent.quiz ? screenContent.quiz : undefined}
              quizTip={screenContent && screenContent.quizTip ? screenContent.quizTip : undefined}
              handleChangeScreen={handleScreenChange}
              showBackBtn={() => moduleContent && moduleContent.moduleIndex === FIRST_POSITION && +screenNum! === FIRST_POSITION}
              screensLength={moduleContent ? moduleContent.screens.length : undefined}
            />
          </div>
        </div>
      </div>
      )}

      <CompletedProgramPopup showPopup={() => setIsProgramCompleted(false)} isProgramCompleted={isProgramCompleted} completedProgram={enrollment ? enrollment.course : undefined} />
    </div>
  );
};

export default Player;
