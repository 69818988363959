import React, { useEffect, useState } from 'react';
import { ANSWER_OUTCOME, QUIZ_SUBMISSION_OPTIONS, QUIZ_TYPES } from '../../constants';
import whiteCheckIcon from '../../assets/images/skinny-check.svg';
import wrongIcon from '../../assets/images/wrong-answer.svg';

interface IProps {
  quizType: string;
  quizStatus: string;
  answers: string[];
  isExplanationViewed: boolean;
  correctAnswer: string;
  handleClick: (option: string) => void;
}

const QuizOptions = ({
  quizType, quizStatus, answers, handleClick, correctAnswer, isExplanationViewed,
}: IProps) => {
  const [selectedOption, setSelectedOption] = useState<string>();

  const { IS_SELECTED } = QUIZ_SUBMISSION_OPTIONS;
  const { SURVEY } = QUIZ_TYPES;

  const { CORRECT, INCORRECT } = ANSWER_OUTCOME;

  useEffect(() => {
    if (isExplanationViewed && quizStatus !== SURVEY) {
      setSelectedOption(correctAnswer);
    }
  }, [isExplanationViewed]);

  const handleAnswerSelection = (option: string) => {
    if (quizStatus !== IS_SELECTED) {
      setSelectedOption(option);
      handleClick(option);
    }
  };

  const handleAnswerPoint = (answer: string) => {
    if (quizStatus === IS_SELECTED && selectedOption === answer && selectedOption === correctAnswer) return 'bg-[#1A8215] border-0';
    if (quizStatus === IS_SELECTED && selectedOption !== correctAnswer && selectedOption === answer && quizType !== SURVEY) return 'bg-[#D9323D] border-0';
    return null;
  };

  const handleCurrentAnswer = (answer: string, index: number) => {
    if (selectedOption === answer && quizStatus === IS_SELECTED && quizType === SURVEY) {
      return <div className='w-[0.875rem] h-[0.875rem] rounded-full bg-orange' />;
    }
    if (quizStatus === IS_SELECTED && answer === selectedOption && quizType !== SURVEY) {
      return <img className='w-[0.75rem]' src={answer === correctAnswer ? whiteCheckIcon : wrongIcon} alt='result' />;
    }
    return <div className='text-grey text-[0.875rem] font-aspira400'>{String.fromCharCode(65 + index)}</div>;
  };

  return (
    <div className='w-full pb-[1.5rem]'>
      {answers.map((answer: string, index: number) => (
        <div
          key={answer}
          className='flex py-[1rem] cursor-pointer'
          aria-hidden
          onClick={() => handleAnswerSelection(answer)}
        >
          <div>
            <div
              className={`flex justify-center items-center h-[1.5rem] w-[1.5rem] rounded-full border border-1 border-[#534F63] mr-[0.75rem] ${handleAnswerPoint(answer)}`}
            >
              {
                answer !== selectedOption
                  ? <div className='text-grey text-[0.875rem] font-aspira400'>{String.fromCharCode(65 + index)}</div>
                  : (
                    <div className='w-full h-full flex justify-center items-center'>
                      { handleCurrentAnswer(answer, index) }
                    </div>
                  )
                }
            </div>
          </div>
          <div className='text-[1rem] text-grey font-aspira400'>{answer}</div>
        </div>
      ))}

      <div className='mb-[3.75rem]'>
        {quizStatus === IS_SELECTED && correctAnswer === selectedOption && quizType !== SURVEY && (
        <div className='max-sm:w-full w-[80%] h-[2.625rem] bg-[#E0FFE0] rounded-[0.5rem] py-[0.75rem] px-[1.75rem] flex items-center font-aspira400 text-grey text-[0.875rem]'>{CORRECT}</div>
        )}

        {quizStatus === IS_SELECTED && correctAnswer !== selectedOption && quizType !== SURVEY && (
        <div className='max-sm:w-full w-[80%] h-[2.625rem] bg-[#FFE0E0] rounded-[0.5rem] py-[0.75rem] px-[1.75rem] flex items-center font-aspira400 text-grey text-[0.875rem]'>
          <div>{INCORRECT}</div>
          <div className='font-aspira550 ml-1'>{String.fromCharCode(65 + answers.indexOf(correctAnswer))}</div>
          <div>.</div>
        </div>
        )}
      </div>
    </div>
  );
};

export default QuizOptions;
