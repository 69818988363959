import React from 'react';
import {
  ESTIMATION, TIME_OPTIONS, COURSE_STATUSES,
} from '../../constants';
import { Clock } from '../index';

interface IProps {
    displayName: string;
    timeEst: any;
    isShowFullSyllabus: boolean;
    screens: number;
    modNumber: number;
    currentScreen: number;
    currentModNumber?: number;
    completed: boolean | null;
}

const ModuleCard = ({
  displayName, timeEst, isShowFullSyllabus, screens, modNumber, currentScreen, currentModNumber, completed,
}: IProps) => (
  <div className={`w-full rounded-[0.75rem] flex flex-col justify-between border border-1 p-[1rem]
    ${(currentModNumber && modNumber < currentModNumber) || completed ? 'bg-greenBackground h-[6.438rem] border-[#1a8215]' : `bg-lightBackground ${isShowFullSyllabus ? 'h-[5.938rem]' : 'h-[5.938rem] max-sm:h-[7.375rem]'}`}
    ${currentModNumber && modNumber && currentModNumber < modNumber && 'border-[#E2E1E6]'}
    ${currentModNumber === modNumber && 'border-[#101828] border-2'}`}
  >
    <div className='flex justify-between gap-[0.625rem]'>
      <div className={`font-aspira700 text-grey text-[1.125rem] ${isShowFullSyllabus ? 'max-sm:truncate' : ''} ${currentModNumber! < modNumber ? 'opacity-50' : ''}`}>
        <span>{displayName}</span>
      </div>

      <div className={`font-aspira700 text-grey text-[1.125rem] ${currentModNumber! < modNumber ? 'opacity-50' : ''}`}>{`${currentScreen}/${screens}`}</div>
    </div>

    {(currentModNumber && modNumber < currentModNumber) || completed
      ? (
        <div className='w-[6.563rem] h-[2rem] flex items-center border border-1 border-[#1a8215] rounded-[0.25rem] justify-center p-[0.5rem]'>
          <div className='text-[0.75rem] text-[#1a8215] font-aspira700 tracking-[0.078rem] text-center'>{COURSE_STATUSES.COMPLETED.toUpperCase()}</div>
        </div>
      ) : (
        <div className='flex items-center h-[0.875rem] mr-1.5'>
          <Clock className='text-[0.875rem]' context={`${timeEst} ${TIME_OPTIONS.MINUTES} ${ESTIMATION}`} />
        </div>
      )}
  </div>
);

export default ModuleCard;
