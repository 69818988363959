import React, { ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Inline } from '@contentful/rich-text-types';

interface IProps {
    children: React.ReactNode;
}

export const Bold = ({ children }: IProps) => <span className='font-aspira700'>{children}</span>;
export const Italic = ({ children }: IProps) => <span className='italic'>{children}</span>;
export const Underline = ({ children }: IProps) => <span className='underline'>{children}</span>;
export const Code = ({ children }: IProps) => <code className='align-center'>{children}</code>;
export const Text = ({ children }: IProps) => <p className='font-aspira400 align-center mb-[1rem]'>{children}</p>;
export const HeadingOne = ({ children }: IProps) => <h1 className='font-aspira700 align-center'>{children}</h1>;
export const HeadingTwo = ({ children }: IProps) => <h2 className='font-aspira700 align-center'>{children}</h2>;
export const HeadingThree = ({ children }: IProps) => <h3 className='font-aspira700 align-center'>{children}</h3>;
export const HeadingFour = ({ children }: IProps) => <h4 className='font-aspira700 align-center'>{children}</h4>;
export const HeadingFive = ({ children }: IProps) => <h5 className='font-aspira700 align-center'>{children}</h5>;
export const HeadingSix = ({ children }: IProps) => <h6 className='font-aspira700 align-center'>{children}</h6>;
export const OrderedList = ({ children }: IProps) => <ol className='list-decimal ml-[1rem] break-words'>{children}</ol>;
export const UnorderedList = ({ children }: IProps) => <ul className='list-disc ml-[1rem] break-words'>{children}</ul>;
export const Quote = ({ children }: IProps) => <q>{children}</q>;
// TODO - check that it works without children then remove ts comment
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HR = ({ children }: IProps) => <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />;

export const Table = ({ children }: IProps) => (
  <table>
    <tbody>{children}</tbody>
  </table>
);

export const TableRow = ({ children }: IProps) => <tr>{children}</tr>;
export const TableHeader = ({ children }: IProps) => <th>{children}</th>;
export const TableData = ({ children }: IProps) => <td>{children}</td>;
export const Sub = ({ children }: IProps) => <sub>{children}</sub>;
export const defaultInline = (type: string, node: Inline): ReactNode => (
  <span key={node.data.target.sys.id}>
    <span>{`type: ${node.nodeType}`}</span>
    <span>{`id: ${node.data.target.sys.id}`}</span>
  </span>
);
