// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import { DEVELOPMENT } from '../constants';

export const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  timeout: 5000,
});

http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    let token;
    let idToken;
    if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) token = localStorage.getItem('token');
    else token = Cookies.get('access_token');

    if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) idToken = localStorage.getItem('token');
    else idToken = Cookies.get('id_token');

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
      config.headers!.Chegg_token = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
