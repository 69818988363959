import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '@chegg-ui/button';
import { ICourse, IEnrollment, IRestructuredCourses } from '../../interfaces';
import {
  Carousel, Dropdown, MobileDrawer, Tag,
} from '../index';
import { courseStructureView } from '../../utils';
import {
  CAREER_PATH, FILTER_EMPTY_SPACE, PROGRAM_LIBRARY_STRINGS, STATUS_DROP_OPTIONS,
} from '../../constants';
import emptyState from '../../assets/images/completed-tab-empty.svg';

interface IProps {
    courses: ICourse[];
    enrollments: IEnrollment[];
    libraryEmpty: (bool: boolean) => void;
}

const CourseLibrary = ({
  courses, enrollments, libraryEmpty,
}: IProps) => {
  const [initialKeys, setInitialKeys] = useState<string[]>([]);
  const [restructuredCourses, setRestructuredCourses] = useState<IRestructuredCourses>();
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<string[]>([]);

  const { TITLE, SUB_TITLE } = PROGRAM_LIBRARY_STRINGS;
  const { DEFAULT, OPTIONS } = STATUS_DROP_OPTIONS;
  const { FILTER_TITLE, FILTER_SUB_TITLE, CLEAR_FILTERS } = FILTER_EMPTY_SPACE;

  useEffect(() => {
    if (courses && enrollments) setRestructuredCourses(courseStructureView(courses, enrollments, selectedStatuses, selectedTracks));
  }, [courses, enrollments, selectedStatuses, selectedTracks]);

  useEffect(() => {
    if (restructuredCourses && !initialKeys.length) setInitialKeys(Object.keys(restructuredCourses).sort());
  }, [restructuredCourses]);

  useEffect(() => {
    if (restructuredCourses && Object.entries(restructuredCourses).length === 0) libraryEmpty(true);
    else libraryEmpty(false);
  }, [restructuredCourses]);

  const handleRemoveTag = (option: string) => {
    if (selectedStatuses.includes(option)) setSelectedStatuses(selectedStatuses.filter(selected => selected !== option));
    else setSelectedTracks(selectedTracks.filter(selected => selected !== option));
  };

  return (
    <div>
      <div className='font-aspira800 text-[#302C41] text-[2.25rem] max-sm:text-[1.75rem] mb-[0.5rem] mt-[3rem]'>{TITLE}</div>
      <div className='font-aspira400 text-[#474358] text-[1.625rem] max-sm:text-[1.25rem] mb-[3.5rem]'>{SUB_TITLE}</div>

      <div className='mb-[3rem] max-sm:hidden'>
        <div className='flex'>
          <div className='mr-[1.5rem]'>
            <Dropdown defaultOption={CAREER_PATH} options={restructuredCourses && initialKeys ? initialKeys : []} selectedOptions={selectedTracks} handleSelected={setSelectedTracks} />
          </div>
          <Dropdown defaultOption={DEFAULT} options={OPTIONS} selectedOptions={selectedStatuses} handleSelected={setSelectedStatuses} />
        </div>

        <div className='flex flex-wrap'>
          {[...selectedStatuses, ...selectedTracks].map((option, index) => (
            <div key={option} className={`mt-[1rem] ${[...selectedStatuses, ...selectedTracks].length !== index + 1 ? 'mr-[1rem]' : ''}`}>
              <Tag name={option} removeTag={() => handleRemoveTag(option)} />
            </div>
          ))}
        </div>
      </div>

      <div className='sm:hidden'>
        <MobileDrawer
          defaultOption={{ status: DEFAULT, track: CAREER_PATH }}
          options={{ status: OPTIONS, track: restructuredCourses && initialKeys ? initialKeys : [] }}
          selectedOptionsStatus={selectedStatuses}
          selectedOptionsTrack={selectedTracks}
          handleSelectedStatus={setSelectedStatuses}
          handleSelectedTrack={setSelectedTracks}
        />
      </div>

      {restructuredCourses && Object.entries(restructuredCourses).length > 0
        ? (
          <div>
            {Object.entries(restructuredCourses).sort().map(([key, values], index) => (
              <div key={key}>
                {values && values.length ? (
                  <div className={index === (Object.entries(restructuredCourses).length - 1) ? 'mb-[4.188rem]' : ''}>
                    <div>
                      <Carousel cards={values} name={key} />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )
        : (
          <div className='w-full h-full flex flex-col justify-center items-center'>
            <img src={emptyState} alt='no-programs-found' className='w-[16.5rem] h-[16.5rem] mb-[2rem] max-sm:mt-[3.5rem]' />
            <div className='text-[#302C41] text-[1.75rem] font-aspira700 mb-[0.5rem]'>{FILTER_TITLE}</div>
            <div className='text-center text-[1.375rem] text-[#474358] font-aspira400 mb-[2rem]'>{FILTER_SUB_TITLE}</div>
            <SecondaryButton
              width='9.563rem'
              onClick={() => {
                setSelectedStatuses([]);
                setSelectedTracks([]);
              }}
              variant='outlined'
            >
              {CLEAR_FILTERS}
            </SecondaryButton>
          </div>
        )}
    </div>
  );
};
export default CourseLibrary;
