import { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { ViewContext, ViewExperience } from '@chegg/rio-sdk-web/dist/types/baseEventWeb';
import { sendRioEvent, updateRioState } from '../indes';

export function useClickstreamView(view_name: string, content_value?: string) {
  useEffect(() => {
    const screenView: ViewContext = {
      view_name,
      experience: 'sfs' as ViewExperience,
      property: 'skills',
    };

    if (content_value) screenView.content = { content_value };
    updateRioState({
      view: screenView,
    });
    sendRioEvent('clickstream_view', {});
  }, [view_name, content_value]);
}
