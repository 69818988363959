export const BACKEND_ROUTES = {
  GET_ALL_COURSES: '/courses/getAllCourses',
  GET_ALL_COURSE_DATA: '/courses/getCourseData',
  GET_ALL_COURSE_ENROLLMENTS: '/courses/getAllCourseEnrollments',
  GET_COURSE_VERSION_STRUCTURE: 'courses/getCourseVersionStructure',
  GET_COURSE_CURRICULUM: 'courses/getCourseCurriculum',
  GET_MODULE: 'courses/getModule',
  CREATE_COURSE_ENROLLMENT: 'courses/createCourseEnrollment',
  CREATE_SCREEN_COMPLETION: 'courses/createScreenCompletion',
  GET_CERTIFICATES: '/courses/certificate',
  SET_SUBSCRIPTION: 'students/setSubscribed',
  SET_STUDENT_INSIGHT: 'students/setStudentInsight',
};
