import React, { useState } from 'react';
import { Button } from '@chegg-ui/button';
import { useNavigate } from 'react-router-dom';
import graduationCap from '../../assets/images/graduation-cap.svg';
import closeIcon from '../../assets/images/close-mobile-menu.svg';
import { COMPLETED_PROGRAM_STRINGS } from '../../constants';
import {
  Confetti,
  WhatsNext,
  // ShareCertificatePopup,
} from '../index';
import { ICourse } from '../../interfaces';
import { DASHBOARD } from '../../pages/route_links';
import { useClickstreamInteraction } from '../../rio/rioHooks/useClickstreamInteraction';
import { downloadCertificate } from '../../services/downloadCertifiacte';

interface IProps {
    showPopup: () => void;
    isProgramCompleted: boolean;
    completedProgram: ICourse | undefined;
}

const CompletedProgramPopup = ({ showPopup, isProgramCompleted, completedProgram }: IProps) => {
  const navigate = useNavigate();
  const [loadingCert, setLoadingCert] = useState(false);
  // const [cert, setCert] = useState<any>();

  const {
    GREAT_JOB, GREAT_JOB_SUB_TITLE, GET_CERT, PROGRAM,
  } = COMPLETED_PROGRAM_STRINGS;

  const handleClosePopup = () => {
    showPopup();
    navigate(DASHBOARD);
  };

  const getCertificate = async () => {
    if (completedProgram) {
      useClickstreamInteraction('click', 'button', 'download certificate', 'Download your certificate');
      setLoadingCert(true);
      const downloadedCert = await downloadCertificate(completedProgram);
      // setCert(downloadedCert);
      setLoadingCert(false);
      if (downloadedCert) handleClosePopup();
    }
  };

  return (
    <div>
      {isProgramCompleted && (<div className='z-[1400] fixed right-0 left-0 top-0 w-full h-full bg-black bg-opacity-60 backdrop-blur-md' />)}
      <div className={`w-screen rounded-t-[4rem] fixed bottom-0 z-[1500] bg-white flex flex-col items-center transform transition-[height] duration-[500ms] ease-linear overflow-auto picker-scroll ${isProgramCompleted ? 'h-[85vh]' : 'h-[0vh]'}`}>
        <div className='fixed bottom-0 w-screen'><Confetti /></div>
        {isProgramCompleted ? (
          <div className='w-full flex flex-col items-center relative'>
            <img src={graduationCap} alt='graduation-hat' className='mt-[2.625rem]' />
            <div className='my-[0.5rem] text-[2.25rem] text-grey font-aspira800'>{GREAT_JOB}</div>
            <div className='text-[1.625rem] max-sm:text-[1.4rem] text-[#474358] font-aspira400 mb-[1.5rem] flex text-center'>
              {`${GREAT_JOB_SUB_TITLE} ${completedProgram ? completedProgram.name : ''} ${PROGRAM}`}
            </div>
            <Button className='h-[3rem] w-[11.5rem]' isLoading={loadingCert} onClick={() => getCertificate()}>{GET_CERT}</Button>

            <WhatsNext />

            <div
              className='cursor-pointer absolute top-[2.625rem] max-sm:right-[2.625rem] right-[4.313rem] h-[2rem] w-[2rem] rounded-full flex justify-center items-center border border-1 border-[#302C41]'
              onClick={() => handleClosePopup()}
              aria-hidden
            >
              <img src={closeIcon} alt='close' className='w-[1.25rem] h-[1.2rem]' />
            </div>
          </div>
        ) : null}
      </div>

      {/* <ShareCertificatePopup certificate={cert} show={!!cert} closePopup={handleClosePopup} /> */}
    </div>
  );
};

export default CompletedProgramPopup;
