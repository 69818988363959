import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';
import { IModuleWithoutScreen } from '../../interfaces';

type State = {
  structure: IModuleWithoutScreen[]
}

type Action = {
  setCourseStructure: (structure: State['structure']) => void
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => (await get(name)) || null,
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

export const courseStructureStore = create<State & Action>()(
  persist(
    // eslint-disable-next-line no-shadow
    (set) => ({
      structure: [],
      setCourseStructure: (setCourseStructure) => set(() => ({ structure: setCourseStructure })),
    }),
    {
      name: 'courseStructure',
      storage: createJSONStorage(() => storage),
    },
  ),
);
