import React from 'react';
import skinnyCheckOrange from '../../assets/images/skinny-check-orange.svg';
import { FINAL_AUTH_FLOW_SCREEN } from '../../constants';

const AuthFlowFinalScreen = () => {
  const {
    title, subTitle, points,
  } = FINAL_AUTH_FLOW_SCREEN;

  return (
    <div>
      <div className='text-[2.25rem] font-aspira800 w-[90%] mb-[2rem]'>{title}</div>
      <div className='text-[#474358] font-aspira700 text-[1.125rem]'>{subTitle}</div>
      <div>
        {points.map(point => (
          <div key={point} className='flex items-center mt-[1rem]'>
            <div>
              <img src={skinnyCheckOrange} alt='check' className='h-[1.25rem] mr-[0.625rem]' />
            </div>
            <span className='text-grey text-[1.25rem] font-aspira400'>{point}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuthFlowFinalScreen;
