/* eslint-disable import/first */
process.env.CHEGG_ENV = process.env.REACT_APP_CHEGG_ENV;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as routeLinks from './pages/route_links';
import {
  Authentication, AuthenticationFlow, Dashboard, Player,
} from './pages';
import { Error, PrivateRoutes } from './components';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      errorElement: <Error />,
      children: [
        {
          element: <PrivateRoutes />,
          children: [
            { path: routeLinks.AUTHENTICATIONFLOW, element: <AuthenticationFlow /> },
            { path: routeLinks.DASHBOARD, element: <Dashboard /> },
            { path: routeLinks.PLAYER, element: <Player /> },
          ],
        },
        { path: routeLinks.AUTHENTICATION, element: <Authentication /> },
      ],
    },
  ],
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
