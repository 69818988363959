interface IProps {
  className: string;
  context: string;
  handleClick?: () => void;
  icon?: string;
  reverse?: boolean;
}

const Button = ({
  className, context, handleClick, icon, reverse,
}: IProps) => (
  <button
    type='button'
    className={className}
    onClick={handleClick}
  >
    {icon
      ? (
        <div className={`flex w-full h-full justify-center items-center ${reverse && ('flex-row-reverse')}`}>
          <div className={reverse ? 'ml-[1rem]' : 'mr-[1rem]'}>{icon && (<img src={icon} alt='icon' />)}</div>
          <div>{context}</div>
        </div>
      )
      : <div>{ context }</div>}
  </button>
);

export default Button;
