import React from 'react';
import { DASH_TYPES } from '../../constants';
import DashButton from '../DashButton';
import menu from '../../assets/images/closed-mobile-menu.svg';
import closeMenu from '../../assets/images/close-mobile-menu.svg';
import { Portal } from '../index';

interface IProps {
    handleDashView: (view: string) => void;
    selectedView: string;
    open: boolean;
    setOpening: () => void;
}

const MobileSidebar = ({
  open, setOpening, handleDashView, selectedView,
}: IProps) => (
  <div>
    <div
      className='h-[6rem] fixed top-0 left-[3.5%] z-30 flex items-center'
      aria-hidden
      onClick={() => setOpening()}
    >
      <img src={open ? closeMenu : menu} alt='nav-menu' className='w-[1.75rem] h-[1.75rem]' />
    </div>
    {
      open && (
        <div className='h-full w-[17rem] z-50 flex flex-col items-center bg-white border-r border-r-1 border-[#E2E1E6] fixed'>
          <div className='pt-[1.5rem] w-full'>
            {DASH_TYPES.map(({ name, icon, selectedIcon }) => (
              <div key={name} aria-hidden onClick={() => handleDashView(name)} className='w-full flex justify-center h-[3rem] mb-[1rem]'>
                <DashButton
                  selectedView={selectedView}
                  buttonName={name}
                  icon={icon}
                  selectedIcon={selectedIcon}
                />
              </div>
            ))}
          </div>
        </div>
      )
    }

    <Portal isOpen={open}>
      <div aria-hidden className='z-10 fixed right-0 left-0 top-0 w-full h-full bg-black bg-opacity-80 backdrop-blur-md' onClick={() => setOpening()} />
    </Portal>
  </div>
);

export default MobileSidebar;
