import React, { useState } from 'react';
import {
  ICourse, ICurriculum, IEnrollment, IExtendedEnrollment, IModule,
} from '../../interfaces';
import {
  CourseLibrary,
  DashSidebar,
  MobileSidebar,
  MyCourses,
  // Unsubscribed,
} from '../index';
import { DASHBOARD_VIEW_TYPES } from '../../constants';
// import { subscriptionStore } from '../../store/Subscription';
import CompletedPrograms from '../CompletedPrograms';

interface IProps {
    courses: ICourse[];
    enrollments: IEnrollment[];
    lastStudied?: IEnrollment;
    lastStudiedMod?: IModule;
    lastStudiedCurriculum?: ICurriculum;
    isNewUser: boolean;
    extendedEnrollment?: IExtendedEnrollment;
}

const FullDashPanel = ({
  courses, enrollments, isNewUser, lastStudied, lastStudiedMod, lastStudiedCurriculum, extendedEnrollment,
}: IProps) => {
  const { MY_COURSES, COURSE_LIBRARY, COMPLETED } = DASHBOARD_VIEW_TYPES;
  const [isOpen, setIsOpen] = useState(false);
  const [isProgramLibraryEmpty, setIsProgramLibraryEmpty] = useState(false);
  const [dashView, setDashView] = useState(MY_COURSES);
  // const [isSubscribed] = subscriptionStore((state) => [state.isSubscribed]);

  const handleChangeDashView = (view: string) => {
    setIsOpen(false);
    setDashView(view);
  };

  return (
    <div className='flex h-full w-full min-h-screen'>
      <div className='max-xl:hidden'>
        <DashSidebar handleDashView={handleChangeDashView} selectedView={dashView} />
      </div>
      <div className='xl:hidden'>
        <MobileSidebar open={isOpen} setOpening={() => setIsOpen(!isOpen)} handleDashView={handleChangeDashView} selectedView={dashView} />
      </div>

      <div className='xl:ml-[17%] xl:w-[83%] max-xl:w-screen h-full xl:pl-[2rem]'>
        <div className={isProgramLibraryEmpty && dashView === COURSE_LIBRARY ? 'hidden' : 'xl:mr-6'}>
          {/* {!isSubscribed && <Unsubscribed />} */}
        </div>
        <div className={`max-xl:pb-[1rem] mr-[1.5rem] max-xl:mx-[1rem] ${isNewUser ? 'mt-[3rem]' : ''}`}>
          {dashView === MY_COURSES && (
          <MyCourses
            enrollments={enrollments}
            courses={courses}
            lastStudied={lastStudied}
            lastStudiedMod={lastStudiedMod}
            lastStudiedCurriculum={lastStudiedCurriculum}
            handleDashView={() => handleChangeDashView(COURSE_LIBRARY)}
            extendedEnrollment={extendedEnrollment}
          />
          )}

          {dashView === COMPLETED && (<CompletedPrograms enrollments={enrollments} handleDashView={() => handleChangeDashView(COURSE_LIBRARY)} />)}

          {dashView === COURSE_LIBRARY && (<CourseLibrary courses={courses} enrollments={enrollments} libraryEmpty={setIsProgramLibraryEmpty} />)}
        </div>
      </div>
    </div>
  );
};

export default FullDashPanel;
