import React from 'react';
import { STEP } from '../../constants';
import check from '../../assets/images/skinny-check.svg';

interface IProps {
    questions: any;
    currentQuestion: number;
}

const AuthFlowProgressBar = ({ questions, currentQuestion }: IProps) => (
  <div className='flex'>
    <div className='flex'>
      {questions.map((question: any, index: number) => (
        <div key={question}>
          <div className='flex items-center'>
            <div
              className={`mx-[0.25rem] h-[2.188rem] w-[2.188rem] rounded-full text-[1.25rem] text-aspira700 flex justify-center items-center 
                ${index + 1 === currentQuestion && 'bg-orange text-white'}
                ${index + 1 < currentQuestion && 'bg-[#1A8215] text-white'}
                ${index + 1 > currentQuestion && 'border border-1 border-[#747283] text-[#747283]'}
              `}
            >
              {index + 1 < currentQuestion
                ? <img src={check} alt='check' className='w-[1.25rem] h-[0.894rem]' />
                : <div>{index + 1}</div>}
            </div>
            <div className={`mx-[0.4rem] w-[10rem] max-sm:w-[5.688rem] h-[0.125rem] rounded-[50px] ${index + 1 < currentQuestion ? 'bg-[#1A8215]' : 'bg-[#CAC8D1]'}`} />
          </div>
          <div className='text-[0.875rem] text-[#302C41] font-aspira700'>
            {STEP}
            {index + 1}
          </div>
        </div>
      ))}
    </div>

    <div>
      <div
        className={`mx-[0.25rem] h-[2rem] w-[2rem] rounded-full text-[1.25rem] flex justify-center items-center 
          ${questions.length + 1 === currentQuestion && 'bg-orange text-white'}
          ${questions.length + 1 < currentQuestion && 'bg-[#1A8215] text-white'}
          ${questions.length + 1 > currentQuestion && 'border border-1 border-[#747283] text-[#747283]'}
        `}
      >
        {questions.length + 1}
      </div>
      <div className='text-[0.875rem] text-[#302C41]'>
        {STEP}
        {questions.length + 1}
      </div>
    </div>
  </div>
);

export default AuthFlowProgressBar;
