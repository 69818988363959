import React from 'react';

interface IProps {
    selectedView: string
    buttonName: string
    icon: string
    selectedIcon: string
}

const DashButton = ({
  selectedView, buttonName, icon, selectedIcon,
}: IProps) => (
  <div className={`relative w-[95%] cursor-pointer ${selectedView === buttonName && ('rounded-md shadow-lastStudiedBox')}`}>
    <div className={selectedView === buttonName ? ('absolute left-0 top-0 h-full w-1.5 rounded-l-lg bg-[#EB7100]') : ''} />
    <div className='flex h-full items-center'>
      <img src={selectedView === buttonName ? selectedIcon : icon} alt='nav-option' className='ml-[0.75rem] h-[1.5rem]' />
      <span className={`${selectedView === buttonName ? 'font-medium' : 'text-lightText'} text-[1.125rem] ml-[0.75rem]`}>
        {buttonName}
      </span>
    </div>
  </div>
);

export default DashButton;
